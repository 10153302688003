<div class="fondo-negro animated fadeIn" *ngIf="__ModalDescriptionItemOurPricesService.oculto!=='oculto'">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">

            <!-- UPDATE -->
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 460px;">
                <div class="modal-header">
                    <h2 *ngIf="__ModalDescriptionItemOurPricesService.id>0 && __ModalDescriptionItemOurPricesService.forWhat==='addOrUpdate'"
                        class="modal-title">
                        Actualizar descripciones del ítem
                    </h2>
                    <h2 *ngIf="__ModalDescriptionItemOurPricesService.id===0 && __ModalDescriptionItemOurPricesService.forWhat==='addOrUpdate'"
                        class="modal-title">
                        Agregar descripciones del ítem
                    </h2>
                    <h2 *ngIf="__ModalDescriptionItemOurPricesService.forWhat === 'changeOrder'" class="modal-title">
                        Ordenar descripciones del ítem
                    </h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div *ngIf="__ModalDescriptionItemOurPricesService.forWhat === 'changeOrder'" class="card-body">
                        <app-drag-and-drop *ngIf="stateGeneral===1 && ARRAY_COMPONENTS.length>0"
                            (emitNewDataOrdered)="changeOrderPosition($event)" [data]="ARRAY_COMPONENTS"
                            [config]="{id: 'cip_id',nameKeyToShow: 'c_name'}" [type]="'text'" [keyToShow]="'text'">
                        </app-drag-and-drop>
                        <p *ngIf="stateGeneral===1 && ARRAY_COMPONENTS.length===0">No hay datos</p>
                    </div>

                    <div *ngIf="__ModalDescriptionItemOurPricesService.forWhat==='addOrUpdate'"
                        [hidden]="stateGeneral===0" class="card-body">

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-4 text-left font-weight-bold mt-2">
                                Título
                            </label>
                            <div class="col-md-9">
                                <input class="w-100 form-control {{theme.background}} {{theme.text}}"
                                    [(ngModel)]="DESCRIPTION_DATA.text" name="text" />
                                <p class="text-danger">{{ERROR_DESCRIPTION_DATA.text}}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-4 text-left font-weight-bold mt-2">
                                Alineación del texto
                            </label>
                            <div class="col-md-9">
                                <select [(ngModel)]="DESCRIPTION_DATA.textAlign" name="textAlign"
                                    class="form-control {{theme.background}} {{theme.text}}">
                                    <option value="left">Izquierda</option>
                                    <option value="center">Centrado</option>
                                    <option value="right">Derecha</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="stateGeneral===0" class="content-wrapper">
                        <i style="position: absolute;top: 170px;left: 50%;margin: -35px 0 0 -35px;"
                            class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>


                </div>
                <div *ngIf="btnUpdate===1 && __ModalDescriptionItemOurPricesService.forWhat==='addOrUpdate'"
                    class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>

                    <button *ngIf="__ModalDescriptionItemOurPricesService.id>0" (click)="update()" type="button"
                        class="btn btn-primary">Actualizar</button>

                    <button *ngIf="__ModalDescriptionItemOurPricesService.id===0" (click)="add()" type="button"
                        class="btn btn-primary">Agregar</button>
                </div>
            </div>

        </div>
    </div>
</div>