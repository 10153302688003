import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalContactUsService {

  public oculto = "oculto";
  public idCFM = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.idCFM = 0;
  }
  mostrarModal(idCFM: number) {
    this.oculto = "";
    this.idCFM = idCFM;
  }
}
