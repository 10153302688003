export const Measures = [
    {
        name: "Auto",
        value: "auto",
    },
    {
        name: "%",
        value: "%",
    },
    {
        name: "px",
        value: "px",
    }
]