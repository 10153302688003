import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: [
    './drag-and-drop.component.css'
  ]
})
export class DragAndDropComponent {

  @Input() type = ""
  @Input() keyToShow = ""
  @Input() data = []
  @Input() config = {
    id: "",
    nameKeyToShow: "",
  }
  @Output() emitNewDataOrdered = new EventEmitter<any>();

  constructor() { }

  drop(event: CdkDragDrop<string[]>) {
    const jsonToSend = {
      data: this.data,
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
    }

    this.emitNewDataOrdered.emit(jsonToSend)
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }

}
