import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectToService {

  constructor(
    public router: Router,
    public http: HttpClient,
    public _usuarioService: UsersService,
  ) { }

  toManagePage(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/managePageById/" + respuesta.tokenData]);
        }
      });
  }

  toHeader(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/header/" + respuesta.tokenData]);
        }
      });
  }

  toMenu(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/navigation/menu/" + respuesta.tokenData]);
        }
      });
  }

  toEditProduct(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/editproduct/" + respuesta.tokenData]);
        }
      });
  }

  toProductPage(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/product/" + respuesta.tokenData]);
        }
      });
  }

  toFooter(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/footer/" + respuesta.tokenData]);
        }
      });
  }

  toSlideshow(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/slideshow/" + respuesta.tokenData]);
        }
      });
  }

  toImageWithTextOverlay(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/imagewithtextoverlay/" + respuesta.tokenData]);
        }
      });
  }

  toImageWithText(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/imagewithtext/" + respuesta.tokenData]);
        }
      });
  }

  toOurPrices(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/ourPrices/" + respuesta.tokenData]);
        }
      });
  }

  toContactUs(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/contactUs/" + respuesta.tokenData]);
        }
      });
  }

  toSectionText(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/sectionText/" + respuesta.tokenData]);
        }
      });
  }

  toSectionCounter(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/sectionCounter/" + respuesta.tokenData]);
        }
      });
  }

  toGallery(data: any) {
    this.generarTokenData(data)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.router.navigate(["/admin/gallery/" + respuesta.tokenData]);
        }
      });
  }

  generarTokenData(data: any) {
    const url = URL_SERVICIOS + "generarToken";

    const dataEnviar = {
      "data": data,
      "token": this._usuarioService.token
    }

    return this.http.post(url, dataEnviar)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  getDataFromToken(token: any) {
    const url = URL_SERVICIOS + "obtener_dataTokenGeneral&token=" + token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

}
