import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { URL_IMG } from 'src/app/config/config';
import { MenuService } from 'src/app/services/menu.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-search-routes',
  templateUrl: './search-routes.component.html',
  styleUrls: []
})
export class SearchRoutesComponent {

  @Output() emitOnChange = new EventEmitter<any>();
  urlGeneralImg = URL_IMG

  dataSearchRoute = {
    wordSearched: ""
  }

  lastSearch = ""
  stateDataToShow = 0

  ITEM_ROUTES = {
    pages: [],
    products: [],
    collections: [],
    urlType: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  // PARA PAGINACIÓN
  pagination = {
    "totalPaginas": 0,
    "totalData": 0,
    "actual": 1,
    "anterior": false,
    "siguiente": false
  }

  offset = 0;
  limit = 50;
  // Fin para paginación

  constructor(
    public _UsersService: UsersService,
    public _MenuService: MenuService,
  ) { }

  setRoute(data: any) {
    let routeFinal = "";

    if (data.split("|")[0] == "HOMEPAGE") {
      routeFinal = "/";
    }
    if (data.split("|")[0] == "ALL_PRODUCTS") {
      routeFinal = "/products";
    }
    if (data.split("|")[0] == "PAGE") {
      routeFinal = "/pagina/" + data.split("|")[1];
    }
    if (data.split("|")[0] == "PRODUCT") {
      routeFinal = data;
    }
    if (routeFinal == "") {
      routeFinal = data;
    }
    this.emitOnChange.emit(routeFinal);
  }

  searchRoute(busqueda: string, desdeInicio = 0) {
    if (this.lastSearch == busqueda) {
      return;
    }
    this.lastSearch = busqueda

    if (desdeInicio == 1) {
      this.offset = 0;
      this.limit = 10;
      this.pagination = {
        "totalPaginas": 0,
        "totalData": 0,
        "actual": 1,
        "anterior": false,
        "siguiente": false
      }
    }

    const dataEnviar = {
      "offset": this.offset,
      "limit": this.limit,
      "wordSearched": busqueda,
      "token": this._UsersService.token
    }
    this.stateDataToShow = 0
    this._MenuService.getMenuProcessedForSearch(dataEnviar)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {

          this.ITEM_ROUTES.pages = respuesta.pages;
          this.ITEM_ROUTES.products = respuesta.products;
          this.ITEM_ROUTES.collections = respuesta.collections;
          this.ITEM_ROUTES.urlType = respuesta.urlType;

          // this.calcularPaginacion(respuesta)
          this.stateDataToShow = 1
          return;
        }
        // this.PRODUCT_DATA = [];
        this.stateDataToShow = 1
      });
  }

}
