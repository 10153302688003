<div class="fondo-negro animated fadeIn" *ngIf="!modalItemSlideshowService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- UPDATE -->
            <div *ngIf="modalItemSlideshowService.id>0" class="modal-content {{theme.background2}} {{theme.text}}"
                style="overflow: auto;height: 660px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar Ítem</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div class="card-body">

                        <div class="form-group row box-shadow-2 p-2 mb-3">
                            <label class="col-md-3 label-control font-medium-4 font-weight-bold mt-2">Color de
                                fondo</label>
                            <div class="col-md-9">
                                <input class="w-100" [(ngModel)]="ITEM_SLIDESHOW_NOW.backgroundColorTextBox"
                                    name="backgroundColorTextBox"
                                    [(colorPicker)]="ITEM_SLIDESHOW_NOW.backgroundColorTextBox"
                                    [style.background]="ITEM_SLIDESHOW_NOW.backgroundColorTextBox" />
                                <p *ngIf="ITEM_SLIDESHOW_NOW_ERROR.backgroundColorTextBox" class="text-danger mb-0">
                                    {{ITEM_SLIDESHOW_NOW_ERROR.backgroundColorTextBox}}
                                </p>
                            </div>
                        </div>

                        <div class="form-group row box-shadow-2 p-2">
                            <label class="col-md-3 label-control font-medium-4 font-weight-bold mt-2">Altura del
                                contenedor</label>
                            <div class="col-md-9">
                                <app-manage-styles *ngIf="stateBox.stylesJsonContainer" [styleToUse]="'height'"
                                    [valueFromDb]="(ITEM_SLIDESHOW_NOW.stylesJsonContainer)?ITEM_SLIDESHOW_NOW.stylesJsonContainer.height:'auto'"
                                    (emitOnChange)="setStyle($event,'height','container')">
                                </app-manage-styles>
                            </div>
                        </div>

                        <div class="accordion">
                            <div class="card box-shadow-2">
                                <div class="card-header p-0">
                                    <h2 class="mb-0">
                                        <button
                                            class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                            type="button" (click)="toggleCollapseTitle()">
                                            <h4 class="text-bold-600 m-3">Título</h4>
                                        </button>
                                    </h2>
                                </div>

                                <div class="collapse {{theme.background2}} {{theme.text}} {{collapseTitle}}">
                                    <div class="card-body">
                                        <app-my-rich-text *ngIf="boxMyRichText.display===1"
                                            [colorText]="ITEM_SLIDESHOW_NOW.titleColor"
                                            [fontSizeText]="ITEM_SLIDESHOW_NOW.titleFontSize"
                                            [boldText]="ITEM_SLIDESHOW_NOW.titleBoldText"
                                            [text]="ITEM_SLIDESHOW_NOW.titleText"
                                            [requestData]="boxMyRichText.requestData"
                                            (emitOnChange)="setTextFromMyRichText($event,'title')">
                                        </app-my-rich-text>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion">
                            <div class="card box-shadow-2">
                                <div class="card-header p-0">
                                    <h2 class="mb-0">
                                        <button
                                            class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                            type="button" (click)="toggleCollapseDescription()">
                                            <h4 class="text-bold-600 m-3">Descripción</h4>
                                        </button>
                                    </h2>
                                </div>

                                <div class="collapse {{theme.background2}} {{theme.text}} {{collapseDescription}}">
                                    <div class="card-body">
                                        <app-my-rich-text *ngIf="boxMyRichText.display===1"
                                            [colorText]="ITEM_SLIDESHOW_NOW.descriptionColor"
                                            [fontSizeText]="ITEM_SLIDESHOW_NOW.descriptionFontSize"
                                            [boldText]="ITEM_SLIDESHOW_NOW.descriptionBoldText"
                                            [text]="ITEM_SLIDESHOW_NOW.descriptionText"
                                            [requestData]="boxMyRichText.requestData"
                                            (emitOnChange)="setTextFromMyRichText($event,'description')">
                                        </app-my-rich-text>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="form-group row">
                            <label class="col-md-12 label-control font-medium-4 font-weight-bold mt-2">Imagen</label>
                            <p *ngIf="(!ITEM_SLIDESHOW_NOW.idImg || +ITEM_SLIDESHOW_NOW.idImg===0)&&stateChooseImg===0"
                                class="cursor-pointer pt-2 text-warning d-inline-block" (click)="stateChooseImg = 1">
                                Seleccionar imagen</p>
                            <div *ngIf="(!ITEM_SLIDESHOW_NOW.idImg || +ITEM_SLIDESHOW_NOW.idImg===0)&&stateChooseImg===1"
                                class="col-md-12 box-shadow-2 bg-white p-2 mb-3">
                                <div class="w-100 text-right">
                                    <span (click)="stateChooseImg = 0" class="text-danger font-large-2 cursor-pointer"
                                        aria-hidden="true">&times;</span>
                                </div>
                                <p class="text-left">Busca, selecciona o agrega una imagen</p>
                                <app-manage-file (emitImageChoosed)="setImg($event,1)"></app-manage-file>
                            </div>
                            <div *ngIf="ITEM_SLIDESHOW_NOW.idImg && ITEM_SLIDESHOW_NOW.idImg>0" class="col-md-12">
                                <button type="button" (click)="removeImgItemById()" class="btn btn-danger mb-0"
                                    style="position: relative;display: block;margin: auto;left: 145px;top: 25px;">
                                    <i class="fa fa-times"></i>
                                </button>

                                <img style="width: 295px;display: block;margin: auto;"
                                    src="{{ITEM_SLIDESHOW_NOW.urlImg}}">
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="btnUpdate===1" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>

                    <button (click)="eventUpdate()" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>