<div class="fondo-negro animated fadeIn" [ngClass]="modalMenuService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- CREATE -->
            <div *ngIf="modalMenuService.id===0" class="modal-content {{theme.background2}} {{theme.text}}"
                style="overflow: auto;height: 300px;">
                <div class="modal-header">
                    <h2 class="modal-title">Agregar menú</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Nombre del menú</label>
                        <div class="col-md-9">
                            <input class="form-control" [(ngModel)]="MENU_DATA.name" name="name" />
                            <p class="text-danger">{{MENU_DATA_ERROR.name}}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="btnPost===1" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>
                    <button (click)="add()" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

            <!-- UPDATE -->
            <div *ngIf="modalMenuService.id>0" class="modal-content {{theme.background2}} {{theme.text}}"
                style="overflow: auto;height: 300px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar menú</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Nombre del menú</label>
                        <div class="col-md-9">
                            <input class="form-control" [(ngModel)]="MENU_DATA.name" name="name" />
                            <p class="text-danger">{{MENU_DATA_ERROR.name}}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="btnPost===1" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>
                    <button (click)="update()" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>