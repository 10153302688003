import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SectionCounterService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  __refresh() {
    this.refresh.emit();
  }

  addOrUpdate(data: any) {
    const url = URL_SERVICIOS + "sectionCounter_addOrUpdate";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  addOrUpdate_item(data: any) {
    const url = URL_SERVICIOS + "sectionCounter_addOrUpdate_item";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  removeItemById(data: any) {
    const url = URL_SERVICIOS + "sectionCounter_removeItemById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  removeCompleteByIdCFM(data: any) {
    const url = URL_SERVICIOS + "sectionCounter_removeCompleteByIdCFM";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  getByIdCFM(data: any) {
    const url = URL_SERVICIOS + "sectionCounter_getByIdCFM&idCFM=" + data.idCFM;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  getItemById(data: any) {
    const url = URL_SERVICIOS + "sectionCounter_getItemById&idItem=" + data.idItem;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  getItemsByIdSectionCounter(data: any) {
    const url = URL_SERVICIOS + "sectionCounter_getItemsByIdSectionCounter&idSectionCounter=" + data.idSectionCounter;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }
}
