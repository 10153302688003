import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SectionCounterService } from 'src/app/services/section-counter.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalSectionCounterService } from './modal-section-counter.service';

@Component({
  selector: 'app-modal-section-counter',
  templateUrl: './modal-section-counter.component.html',
  styles: [
  ]
})
export class ModalSectionCounterComponent implements DoCheck {

  nVez = 0

  stateGeneral = 0

  collapseTitle = ""
  collapseDescription = ""
  collapseButtonAction = ""
  stateChooseImg = 0

  btnUpdate = 1

  boxMyRichText = {
    display: 0,
    requestData: 0
  }



  SECTION_COUNTER_NOW = {
    id: 0,
    idImg: 0,
    urlImg: "",

    titleText: "",
    titleColor: "",
    titleFontSize: 0,
    titleBoldText: "unset",

    descriptionText: "",
    descriptionColor: "",
    descriptionFontSize: 0,
    descriptionBoldText: "unset",

    ON_EDIT_STYLES: {
      boxGeneral_backgroundColor: '',
    },
    jsonStyle: "",
  }

  // ERROR_IWT_NOW = {
  //   backgroundColorTextBox: "",
  //   backgroundColorGeneralBox: "",
  // }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    public sectionCounterService: SectionCounterService,
    private toastr: ToastrService,
    public modalSectionCounterService: ModalSectionCounterService,
  ) { }

  ngDoCheck() {

    if (this.modalSectionCounterService.oculto === "" && this.modalSectionCounterService.idCFM > 0 && this.nVez === 0) {
      this.getByIdCFM().then(
        () => {

        }
      );
      this.nVez = 1;
    }
  }

  getByIdCFM(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.boxMyRichText.display = 0
      this.stateGeneral = 0
      const dataEnviar = {
        "idCFM": this.modalSectionCounterService.idCFM,
        "token": this.usersService.token
      }

      this.sectionCounterService.getByIdCFM(dataEnviar)
        .subscribe((respuesta: any) => {
          this.boxMyRichText.display = 1
          this.stateGeneral = 1
          if (respuesta.ok && respuesta.data_array.length > 0) {
            this.SECTION_COUNTER_NOW.id = respuesta.data_array[0].id
            this.SECTION_COUNTER_NOW.titleText = (respuesta.data_array[0].titleText) ? respuesta.data_array[0].titleText : ""
            this.SECTION_COUNTER_NOW.titleColor = (respuesta.data_array[0].titleColor) ? respuesta.data_array[0].titleColor : ""
            this.SECTION_COUNTER_NOW.titleFontSize = (respuesta.data_array[0].titleFontSize) ? respuesta.data_array[0].titleFontSize : ""
            this.SECTION_COUNTER_NOW.titleBoldText = respuesta.data_array[0].titleBoldText
            this.SECTION_COUNTER_NOW.descriptionText = (respuesta.data_array[0].descriptionText) ? respuesta.data_array[0].descriptionText : ""
            this.SECTION_COUNTER_NOW.descriptionColor = (respuesta.data_array[0].descriptionColor) ? respuesta.data_array[0].descriptionColor : ""
            this.SECTION_COUNTER_NOW.descriptionFontSize = (respuesta.data_array[0].descriptionFontSize) ? respuesta.data_array[0].descriptionFontSize : ""
            this.SECTION_COUNTER_NOW.descriptionBoldText = respuesta.data_array[0].descriptionBoldText
            this.SECTION_COUNTER_NOW.jsonStyle = respuesta.data_array[0].jsonStyle


            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  eventUpdate() {
    this.btnUpdate = 0
    this.boxMyRichText.requestData = 1;
    this.stateGeneral = 0

    setTimeout(() => {
      this.update()
    }, 2000);
    return;
  }

  update() {

    if (!this.validarCampo()) {
      this.stateGeneral = 1
      return;
    }

    const dataEnviar = {
      "idCFM": this.modalSectionCounterService.idCFM,

      "titleText": this.SECTION_COUNTER_NOW.titleText,
      "titleFontSize": this.SECTION_COUNTER_NOW.titleFontSize,
      "titleColor": this.SECTION_COUNTER_NOW.titleColor,
      "titleBoldText": this.SECTION_COUNTER_NOW.titleBoldText,

      "descriptionText": this.SECTION_COUNTER_NOW.descriptionText,
      "descriptionColor": this.SECTION_COUNTER_NOW.descriptionColor,
      "descriptionFontSize": this.SECTION_COUNTER_NOW.descriptionFontSize,
      "descriptionBoldText": this.SECTION_COUNTER_NOW.descriptionBoldText,

      "idImgBackground": this.SECTION_COUNTER_NOW.idImg,
      "jsonStyle": this.SECTION_COUNTER_NOW.jsonStyle,

      "token": this.usersService.token
    }

    this.sectionCounterService.addOrUpdate(dataEnviar)
      .subscribe((respuesta: any) => {
        this.btnUpdate = 1

        if (respuesta.ok) {
          this.sectionCounterService.__refresh()
          this.toastr.success("Actualizado", 'Éxito!', { timeOut: 7000 });
          this.closeModal()
          return
        }
        this.toastr.error("ocurrió un error", 'Advertencia!', { timeOut: 7000 });
        this.closeModal()
      });
  }

  closeModal() {
    this.nVez = 0
    this.stateGeneral = 0
    this.btnUpdate = 1

    this.boxMyRichText = {
      display: 0,
      requestData: 0
    }

    this.collapseTitle = ""
    this.collapseDescription = ""

    this.SECTION_COUNTER_NOW = {
      id: 0,
      idImg: 0,
      urlImg: "",

      titleText: "",
      titleColor: "",
      titleFontSize: 0,
      titleBoldText: "unset",

      descriptionText: "",
      descriptionColor: "",
      descriptionFontSize: 0,
      descriptionBoldText: "unset",

      ON_EDIT_STYLES: {
        boxGeneral_backgroundColor: '',
      },
      jsonStyle: "",
    }

    // this.ERROR_SECTION_COUNTER_NOW = {
    //   backgroundColor: "",
    //   backgroundColorTextBox: "",
    // }
    this.modalSectionCounterService.ocultarModal()
  }

  limpiar() {
    // this.ERROR_SECTION_COUNTER_NOW = {
    //   backgroundColorTextBox: "",
    //   backgroundColorGeneralBox: "",
    // }
  }

  validarCampo() {
    // this.limpiar();

    // if (this.IWT_NOW.backgroundColorTextBox && (this.IWT_NOW.backgroundColorTextBox).length > 25) {
    //   this.ERROR_IWT_NOW.backgroundColorTextBox = "Este campo no puede tener mas de 25 caracteres";
    //   return false;
    // }

    // if (this.IWT_NOW.backgroundColorGeneralBox && (this.IWT_NOW.backgroundColorGeneralBox).length > 25) {
    //   this.ERROR_IWT_NOW.backgroundColorGeneralBox = "Este campo no puede tener mas de 25 caracteres";
    //   return false;
    // }

    // this.limpiar();
    return true;
  }

  toggleCollapseTitle() {
    if (this.collapseTitle === "") {
      this.collapseTitle = "show"
    } else {
      this.collapseTitle = ""
    }
  }

  toggleCollapseDescription() {
    if (this.collapseDescription === "") {
      this.collapseDescription = "show"
    } else {
      this.collapseDescription = ""
    }
  }

  setTextFromMyRichText(e: any, to: string) {
    setTimeout(() => {
      if (to === "title") {
        this.boxMyRichText.display = 0

        this.SECTION_COUNTER_NOW.titleText = e.text
        this.SECTION_COUNTER_NOW.titleFontSize = e.fontSizeText
        this.SECTION_COUNTER_NOW.titleColor = e.colorText
        this.SECTION_COUNTER_NOW.titleBoldText = e.boldText
        return
      }

      if (to === "description") {
        this.boxMyRichText.display = 0

        this.SECTION_COUNTER_NOW.descriptionText = e.text
        this.SECTION_COUNTER_NOW.descriptionFontSize = e.fontSizeText
        this.SECTION_COUNTER_NOW.descriptionColor = e.colorText
        this.SECTION_COUNTER_NOW.descriptionBoldText = e.boldText
        return
      }

    }, 500);
  }

}
