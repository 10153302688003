<div class="fondo-negro animated fadeIn" [ngClass]="modalSectionTextService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- UPDATE -->
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 660px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar sección de texto</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div [hidden]="stateGeneral===0" class="card-body">
                        <div class="row">
                            <label class="col-12 label-control font-medium-5 font-weight-bold mt-2 mb-3 text-left">
                                Fondo de la sección
                            </label>
                        </div>

                        <div class="form-group row mt-2">
                            <label class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">
                                Color de fondo
                            </label>
                            <div class="col-md-9">
                                <input class="w-100" [(ngModel)]="TEXTS_DATA.backgroundColor" name="backgroundColor"
                                    [(colorPicker)]="TEXTS_DATA.backgroundColor"
                                    [style.background]="TEXTS_DATA.backgroundColor" />
                            </div>
                        </div>

                        <div class="row">
                            <label class="col-12 label-control font-medium-5 font-weight-bold mt-3 mb-3 text-left">
                                Datos del contenido
                            </label>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">
                                Color de fondo
                            </label>
                            <div class="col-md-9">
                                <input class="w-100" [(ngModel)]="TEXTS_DATA.backgroundColorTextBox"
                                    name="backgroundColorTextBox" [(colorPicker)]="TEXTS_DATA.backgroundColorTextBox"
                                    [style.background]="TEXTS_DATA.backgroundColorTextBox" />
                                <!-- <p *ngIf="TEXTS_DATA_ERROR.backgroundColorTextBox" class="text-danger mb-0">
                                    {{TEXTS_DATA_ERROR.backgroundColorTextBox}}
                                </p> -->
                            </div>
                        </div>


                        <div class="accordion">
                            <div class="card box-shadow-2">
                                <div class="card-header p-0">
                                    <h2 class="mb-0">
                                        <button
                                            class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                            type="button" (click)="toggleCollapseTitle()">
                                            <h4 class="text-bold-600 m-3">Título</h4>
                                        </button>
                                    </h2>
                                </div>

                                <div class="collapse {{theme.background2}} {{theme.text}} {{collapseTitle}}">
                                    <div class="card-body">
                                        <app-my-rich-text *ngIf="boxMyRichText.display===1"
                                            [colorText]="TEXTS_DATA.titleColor"
                                            [fontSizeText]="TEXTS_DATA.titleFontSize"
                                            [boldText]="TEXTS_DATA.titleBoldText" [text]="TEXTS_DATA.titleText"
                                            [requestData]="boxMyRichText.requestData"
                                            (emitOnChange)="setTextFromMyRichText($event,'title')">
                                        </app-my-rich-text>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion">
                            <div class="card box-shadow-2">
                                <div class="card-header p-0">
                                    <h2 class="mb-0">
                                        <button
                                            class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                            type="button" (click)="toggleCollapseDescription()">
                                            <h4 class="text-bold-600 m-3">Descripción</h4>
                                        </button>
                                    </h2>
                                </div>

                                <div class="collapse {{theme.background2}} {{theme.text}} {{collapseDescription}}">
                                    <div class="card-body">
                                        <app-my-rich-text *ngIf="boxMyRichText.display===1"
                                            [colorText]="TEXTS_DATA.descriptionColor"
                                            [fontSizeText]="TEXTS_DATA.descriptionFontSize"
                                            [boldText]="TEXTS_DATA.descriptionBoldText"
                                            [text]="TEXTS_DATA.descriptionText"
                                            [requestData]="boxMyRichText.requestData"
                                            (emitOnChange)="setTextFromMyRichText($event,'description')">
                                        </app-my-rich-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="stateGeneral===0" class="content-wrapper">
                        <i style="position: absolute;top: 170px;left: 50%;margin: -35px 0 0 -35px;"
                            class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>


                </div>
                <div *ngIf="btnUpdate===1" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>

                    <button (click)="eventUpdate()" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>