<div class="fondo-negro animated fadeIn" [ngClass]="modalPagesService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content {{theme.background2}} {{theme.text}}">
                <div class="modal-header">

                    <h2 *ngIf="modalPagesService.id===0" class="modal-title">Nueva página</h2>
                    <h2 *ngIf="modalPagesService.id>0" class="modal-title">Actualizar página</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div class="card-body">
                        <div class="form-group row mb-1">
                            <label class="col-md-3 label-control" for="striped-form-4">Nombre</label>
                            <div class="col-md-9">
                                <input [(ngModel)]="PAGE_DATA.name" type="text"
                                    class="form-control {{theme.background}} {{theme.text}}" name="name"
                                    placeholder="Nombre">
                                <p class="text-danger">{{PAGE_DATA_ERROR.name}}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control" for="striped-form-4">Ruta</label>
                            <div class="col-md-9">
                                <input [(ngModel)]="PAGE_DATA.route" type="text"
                                    class="form-control {{theme.background}} {{theme.text}}" name="route"
                                    placeholder="Código de ruta">
                                <p class="text-danger">{{PAGE_DATA_ERROR.route}}</p>
                                <p class="text-warning">Solo poner el código de la página para identificarlo</p>
                                <p class="text-left">La ruta final:
                                    <span class="text-bold-700">/pagina/<span
                                            class="text-warning text-bold-700">{{PAGE_DATA.route}}</span></span>

                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button *ngIf="modalPagesService.id===0" (click)="addPage()" type="button"
                        class="btn btn-primary">Agregar</button>
                    <button *ngIf="modalPagesService.id>0" (click)="updatePage()" type="button"
                        class="btn btn-primary">Agregar</button>
                </div>
            </div>
        </div>
    </div>
</div>