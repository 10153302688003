import { Component, OnInit, Input } from '@angular/core';
import { CipHeaderService } from 'src/app/services/cip-header.service';
import { UsersService } from 'src/app/services/users.service';
import { URL_IMG } from 'src/app/config/config';
import { CartService } from 'src/app/services/cart.service';
import { MenuService } from 'src/app/services/menu.service';
import { RedirectToService } from 'src/app/services/redirect-to.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styles: [
  ]
})
export class MenuComponent implements OnInit {
  @Input() vista = "COMPLETE";

  DATA = {
    "backgroundColor": "",
    "colorText": "",
    "routeImage": "",
    "idMenu": "",
    "displayCart": 0,
    "displaySearch": 0,
    "displayWishList": 0,
  }

  ITEMS_MENU = []

  statusIsLoggedOn: any = null

  statusToShow = {
    activeMenuState: "",
    activeMenuMobileState: "",
    activeWishListState: "",
    activeCartState: "",
    activeSearchState: "",
  }

  constructor(
    public _UsersService: UsersService,
    public __CipHeaderService: CipHeaderService,
    public _CartService: CartService,
    public menuService: MenuService,
    public redirectTo: RedirectToService,
  ) { }

  ngOnInit(): void {
    this.cargarDatos()
    this._UsersService.refreshStateLoginUser.subscribe((resp) => {
      this.isLoggedOn()
    });

    this._CartService.refresh.subscribe((resp) => {
      this.statusToShow = {
        activeMenuState: "",
        activeMenuMobileState: "",
        activeWishListState: "",
        activeCartState: "",
        activeSearchState: "",
      }
    });
  }

  cargarDatos() {

    this.getHeaderDataForStore().then(
      () => {
        this.isLoggedOn().then(
          () => {
            this.getAllItemsByIdMenu().then(
              () => {

              }
            );
          }
        );
      }
    );
  }

  getAllItemsByIdMenu(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "idMenu": this.DATA.idMenu
      }

      this.menuService.getAllItemsByIdMenu(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.ITEMS_MENU = respuesta.data_array
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  toggleSubMenus(id: number) {
    const box = document.getElementById("boxSubMenuOfMenu_" + id);
    if (box) {
      if (box.style.display == "block") {
        box.style.display = 'none';
      } else {
        box.style.display = 'block';
      }
    }
  }

  goToUrlCustom(mode: string, url: string, samePage: number) {
    if (mode == "URL") {
      if (samePage == 0) {
        window.location.href = url;
      } else {
        window.open(url, '_blank');
      }
    }

    if (mode == "PRODUCT") {
      const data = url.split("|");
      this.goToProductPage(data[1])
    }

  }

  getHeaderDataForStore(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "ipClient": "",
      }

      this.__CipHeaderService.getHeaderDataForStore(dataEnviar)
        .subscribe((respuesta: any) => {
          console.log(respuesta);

          if (respuesta.ok) {
            this.DATA.backgroundColor = respuesta.data_array[0].CIPH_backgroundColor
            this.DATA.colorText = respuesta.data_array[0].CIPH_colorText
            this.DATA.idMenu = respuesta.data_array[0].CIPH_idMenu
            this.DATA.displayCart = respuesta.data_array[0].CIPH_displayCart
            this.DATA.displaySearch = respuesta.data_array[0].CIPH_displaySearch
            this.DATA.displayWishList = respuesta.data_array[0].CIPH_displayWishList

            if (respuesta.data_array[0].i_route) {
              this.DATA.routeImage = URL_IMG + respuesta.data_array[0].i_route;
            }

            resolve(true);
            return;
          }

        });
    })
  }

  isLoggedOn(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this._UsersService.estaLogueado()
        .subscribe((respuesta: any) => {
          if (respuesta) {
            this.statusIsLoggedOn = 1
            resolve(true);
            return;
          }
          this.statusIsLoggedOn = 0
          resolve(true);
        });
    })
  }

  toggleActiveMenu(value: string) {
    this.statusToShow.activeMenuState = value
  }

  toggleActiveMenuMobileState() {
    if (this.statusToShow.activeMenuMobileState == "active-menuMobile-overlay") {
      this.statusToShow.activeMenuMobileState = ""
      return
    }
    if (this.statusToShow.activeMenuMobileState == "") {
      this.statusToShow.activeMenuMobileState = "active-menuMobile-overlay"
      return
    }
  }

  toggleActiveCart() {
    if (this.statusToShow.activeCartState == "active") {
      this.statusToShow.activeCartState = ""
      return
    }
    if (this.statusToShow.activeCartState == "") {
      this.statusToShow.activeCartState = "active"
      return
    }
  }

  toggleActiveSearchState() {
    if (this.statusToShow.activeSearchState == "active") {
      this.statusToShow.activeSearchState = ""
      return
    }
    if (this.statusToShow.activeSearchState == "") {
      this.statusToShow.activeSearchState = "active"
      return
    }
  }

  toggleActiveWishListState() {
    if (this.statusToShow.activeWishListState == "active-wishlist-overlay") {
      this.statusToShow.activeWishListState = ""
      return
    }
    if (this.statusToShow.activeWishListState == "") {
      this.statusToShow.activeWishListState = "active-wishlist-overlay"
      return
    }
  }

  goToProductPage(idProduct: any) {
    const dataToSend = {
      idProduct,
    }

    this.redirectTo.toProductPage(dataToSend)
  }

}
