import { Component, DoCheck, OnInit } from '@angular/core';
import { ComponentsService } from 'src/app/services/components.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalComponentService } from './modal-component.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-component',
  templateUrl: './modal-component.component.html',
  styles: [
  ]
})
export class AddComponentComponent implements DoCheck {

  nVez = 0

  stateToShowGeneral = 0;

  ARRAY_COMPONENTS = []

  DATA_TO_ADD = {
    idComponent: null
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    private toastr: ToastrService,
    public modalComponentService: ModalComponentService,
    public componentsService: ComponentsService,
  ) { }

  ngDoCheck() {
    if (this.modalComponentService.oculto == "" && this.nVez == 0 && this.modalComponentService.forWhat == "add") {
      this.getComponentsAvaibleToChoose()
      this.nVez = 1;
    }
    if (this.modalComponentService.oculto == "" && this.nVez == 0 && this.modalComponentService.forWhat == "changeOrder") {
      this.getComponentsByPage()
      this.nVez = 1;
    }
  }

  displayImage() {
    const element = (document.getElementById("_" + this.DATA_TO_ADD.idComponent) as HTMLInputElement).value;
    const showImgComponent = (document.getElementById("showImgComponent") as HTMLInputElement);
    showImgComponent.src = "../../../assets/img/forComponents/" + element
  }

  getComponentsByPage(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.stateToShowGeneral = 0;
      const dataEnviar = {
        "ipClient": this.usersService.ipClient,
        "page": this.modalComponentService.page,
        "withAllStore": 0,
      }

      this.componentsService.getComponentsByPage(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.ARRAY_COMPONENTS = respuesta.data_array
            this.stateToShowGeneral = 1;
            resolve(true);
            return;
          }
          this.ARRAY_COMPONENTS = []
          reject(false);
        });
      return;


    })
  }

  changeOrderPosition(e: any = []) {
    let newData = "";
    e.data.forEach((element: any) => {
      if (newData == "") {
        newData += element.cip_id
      } else {
        newData += "|" + element.cip_id
      }
    });
    const dataEnviar = {
      "page": "HOME_PAGE",
      "token": this.usersService.token,
      "data": newData,
      "previousIndex": e.previousIndex,
      "currentIndex": e.currentIndex,
    }

    this.componentsService.changeOrderPositionComponentbyPage(dataEnviar)
      .subscribe((resp2: any) => {
        this.componentsService.__refreshComponentsToPage()
        if (resp2.ok) {
          this.getComponentsByPage()
        } else {
          this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  getComponentsAvaibleToChoose(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "token": this.usersService.token
      }

      this.componentsService.getComponentsAvaibleToChoose(dataEnviar)
        .subscribe((respuesta: any) => {
          this.stateToShowGeneral = 1;
          if (respuesta.ok) {
            this.ARRAY_COMPONENTS = respuesta.data_array
            resolve(true);
            return;
          }
          this.ARRAY_COMPONENTS = []
          reject(false);
        });
    })
  }

  addComponentToPage() {

    const json = {
      idComponent: this.DATA_TO_ADD.idComponent,
      page: this.modalComponentService.page,
      token: this.usersService.token
    }

    this.componentsService.addComponentToPage(json)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Componente agregado con éxito.", 'Éxito!', { timeOut: 4000 });
          this.componentsService.__refreshComponentsToPage()
          this.cerrarModal()
        } else {
          this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  cerrarModal() {
    this.nVez = 0
    this.stateToShowGeneral = 0;

    this.ARRAY_COMPONENTS = []

    this.DATA_TO_ADD = {
      idComponent: null
    }

    const showImgComponent = (document.getElementById("showImgComponent") as HTMLInputElement);
    showImgComponent.src = "";

    this.modalComponentService.ocultarModal();
  }

}
