<div class="fondo-negro animated fadeIn" [ngClass]="__ModalHeaderService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 500px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar Header</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body" align="center">
                    <div class="card-body">
                        <div class="form-group row">
                            <label
                                class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">Logo</label>
                            <div *ngIf="!CPI_HEADER.idImage || CPI_HEADER.idImage===0" class="col-md-9">
                                <p class="text-left">Busca, selecciona o agrega una imagen</p>
                                <app-manage-file (emitImageChoosed)="setImg($event)"></app-manage-file>
                            </div>
                            <div *ngIf="CPI_HEADER.idImage && CPI_HEADER.idImage>0" class="col-md-9">
                                <button type="button" (click)="removeImg()" class="btn btn-danger mb-0"
                                    style="position: relative;display: block;margin: auto;left: 145px;top: 25px;">
                                    <i class="fa fa-times"></i>
                                </button>

                                <img style="width: auto;max-height:96px;display: block;margin: auto;"
                                    src="{{CPI_HEADER.urlImg}}">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">
                                Color de fondo
                            </label>
                            <div class="col-md-9">
                                <input class="w-100" [(ngModel)]="CPI_HEADER.backgroundColor" name="backgroundColor"
                                    [(colorPicker)]="CPI_HEADER.backgroundColor"
                                    [style.background]="CPI_HEADER.backgroundColor" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">
                                Color de texto
                            </label>
                            <div class="col-md-9">
                                <input class="w-100" [(ngModel)]="CPI_HEADER.textColor" name="textColor"
                                    [(colorPicker)]="CPI_HEADER.textColor" [style.background]="CPI_HEADER.textColor" />
                            </div>
                        </div>

                        <div class="form-group row mt-1">
                            <label class="col-md-3 col-form-label">Menú</label>
                            <div class="col-md-9">
                                <select [(ngModel)]="CPI_HEADER.menuId" name="menuId"
                                    class="form-control {{theme.background}} {{theme.text}}">
                                    <option value="0">No</option>
                                    <option *ngFor="let menu of MENUS" value="{{menu['id']}}">{{menu['name']}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>
                    <button (click)="update()" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>