import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SectionCounterService } from 'src/app/services/section-counter.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalItemCounterService } from './modal-item-counter.service';

@Component({
  selector: 'app-modal-item-counter',
  templateUrl: './modal-item-counter.component.html',
  styles: [
  ]
})
export class ModalItemCounterComponent implements DoCheck {

  nVez = 0

  stateGeneral = 0

  collapseTitle = ""
  collapseDescription = ""
  collapseButtonAction = ""
  stateChooseImg = 0

  btnUpdate = 1

  boxMyRichText = {
    display: 0,
    requestData: 0
  }

  ITEM_COUNTER_NOW = {
    id: 0,
    quantityToCount: 0,
    icon: "plus",

    descriptionText: "",
    descriptionColor: "",
    descriptionFontSize: 0,
    descriptionBoldText: "unset",
  }

  ERROR_ITEM_COUNTER_NOW = {
    quantityToCount: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    public sectionCounterService: SectionCounterService,
    private toastr: ToastrService,
    public modalItemCounterService: ModalItemCounterService,
  ) { }

  ngDoCheck() {

    if (this.modalItemCounterService.oculto == "" && this.modalItemCounterService.idSectionCounter > 0 && this.nVez == 0) {
      this.stateGeneral = 1;
      this.nVez = 1;
      this.boxMyRichText.display = 1;
    }
    if (this.modalItemCounterService.oculto == "" && this.modalItemCounterService.id > 0 && this.nVez == 0) {
      this.getItemById()

      this.stateGeneral = 1;
      this.nVez = 1;
    }
  }

  eventUpdate() {
    this.btnUpdate = 0
    this.boxMyRichText.requestData = 1;
    this.stateGeneral = 0

    setTimeout(() => {
      this.addOrUpdate()
    }, 2000);
    return;
  }

  addOrUpdate() {

    if (!this.validarCampo()) {
      this.stateGeneral = 1
      return;
    }

    const dataEnviar = {
      "id": this.modalItemCounterService.id,
      "idSectionCounter": this.modalItemCounterService.idSectionCounter,

      "descriptionText": this.ITEM_COUNTER_NOW.descriptionText,
      "descriptionColor": this.ITEM_COUNTER_NOW.descriptionColor,
      "descriptionFontSize": this.ITEM_COUNTER_NOW.descriptionFontSize,
      "descriptionBoldText": this.ITEM_COUNTER_NOW.descriptionBoldText,

      "icon": this.ITEM_COUNTER_NOW.icon,
      "quantityToCount": this.ITEM_COUNTER_NOW.quantityToCount,

      "token": this.usersService.token
    }

    this.sectionCounterService.addOrUpdate_item(dataEnviar)
      .subscribe((respuesta: any) => {
        this.btnUpdate = 1

        if (respuesta.ok) {
          this.sectionCounterService.__refresh()
          this.toastr.success("Guardado", 'Éxito!', { timeOut: 7000 });
          this.closeModal()
          return
        }
        this.toastr.error("ocurrió un error", 'Advertencia!', { timeOut: 7000 });
        this.closeModal()
      });
  }

  getItemById(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.boxMyRichText.display = 0
      const dataEnviar = {
        "idItem": this.modalItemCounterService.id,
        "token": this.usersService.token
      }

      this.sectionCounterService.getItemById(dataEnviar)
        .subscribe((respuesta: any) => {
          this.boxMyRichText.display = 1

          if (respuesta.ok && respuesta.data_array.length > 0) {
            this.ITEM_COUNTER_NOW.id = respuesta.data_array[0].id
            this.ITEM_COUNTER_NOW.descriptionText = (respuesta.data_array[0].descriptionText) ? respuesta.data_array[0].descriptionText : ""
            this.ITEM_COUNTER_NOW.descriptionColor = (respuesta.data_array[0].descriptionColor) ? respuesta.data_array[0].descriptionColor : ""
            this.ITEM_COUNTER_NOW.descriptionFontSize = (respuesta.data_array[0].descriptionFontSize) ? respuesta.data_array[0].descriptionFontSize : ""
            this.ITEM_COUNTER_NOW.descriptionBoldText = respuesta.data_array[0].descriptionBoldText
            this.ITEM_COUNTER_NOW.icon = respuesta.data_array[0].icon
            this.ITEM_COUNTER_NOW.quantityToCount = respuesta.data_array[0].quantityToCount
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  validarCampo() {
    this.limpiar();
    if (this.ITEM_COUNTER_NOW.quantityToCount > 999999) {
      this.ITEM_COUNTER_NOW.quantityToCount = 0;
      this.ERROR_ITEM_COUNTER_NOW.quantityToCount = "Este campo no puede ser mayor a 999999";
      return false;
    }
    if (this.ITEM_COUNTER_NOW.quantityToCount < 0) {
      this.ITEM_COUNTER_NOW.quantityToCount = 0;
      this.ERROR_ITEM_COUNTER_NOW.quantityToCount = "Este campo no puede ser menor a 0";
      return false;
    }
    this.limpiar();
    return true;
  }

  setTextFromMyRichText(e: any, to: string) {
    setTimeout(() => {

      if (to == "description") {
        this.boxMyRichText.display = 0

        this.ITEM_COUNTER_NOW.descriptionText = e.text
        this.ITEM_COUNTER_NOW.descriptionFontSize = e.fontSizeText
        this.ITEM_COUNTER_NOW.descriptionColor = e.colorText
        this.ITEM_COUNTER_NOW.descriptionBoldText = e.boldText
        return
      }

    }, 500);
  }

  toggleCollapseDescription() {
    if (this.collapseDescription == "") {
      this.collapseDescription = "show"
    } else {
      this.collapseDescription = ""
    }
  }

  closeModal() {
    this.nVez = 0
    this.stateGeneral = 0
    this.btnUpdate = 1

    this.boxMyRichText = {
      display: 0,
      requestData: 0
    }

    this.collapseTitle = ""
    this.collapseDescription = ""

    this.ITEM_COUNTER_NOW = {
      id: 0,
      quantityToCount: 0,
      icon: "plus",

      descriptionText: "",
      descriptionColor: "",
      descriptionFontSize: 0,
      descriptionBoldText: "unset",
    }

    this.ERROR_ITEM_COUNTER_NOW = {
      quantityToCount: "",
    }

    this.modalItemCounterService.ocultarModal()
  }

  limpiar() {
    this.ERROR_ITEM_COUNTER_NOW = {
      quantityToCount: "",
    }
  }

}
