import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GalleryService } from 'src/app/services/gallery.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalGalleryItemService } from './modal-gallery-item.service';
import { URL_IMG } from 'src/app/config/config';

@Component({
  selector: 'app-modal-gallery-item',
  templateUrl: './modal-gallery-item.component.html',
  styles: [
  ]
})
export class ModalGalleryItemComponent implements DoCheck {

  nVez = 0

  stateGeneral = 0
  btnUpdate = 1
  stateChooseImg = 0
  stateToShowSearchRoutes = 1
  urlSelected = ""

  ITEM_GALLERY_NOW = {
    id: 0,
    idImg: 0,
    urlImg: "",
    url: "",
  }

  ERROR_ITEM_GALLERY_NOW = {
    quantityToCount: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    private toastr: ToastrService,
    public __ModalGalleryItemService: ModalGalleryItemService,
    public galleryService: GalleryService,
  ) { }

  ngDoCheck() {

    if (this.__ModalGalleryItemService.oculto == "" && this.__ModalGalleryItemService.idGallery > 0 && this.nVez == 0) {
      this.stateGeneral = 1;
      this.nVez = 1;
    }
  }

  eventUpdate() {
    this.btnUpdate = 0
    this.stateGeneral = 0

    setTimeout(() => {
      this.addOrUpdate()
    }, 2000);
    return;
  }

  addOrUpdate() {
    if (!this.ITEM_GALLERY_NOW.idImg) {
      this.btnUpdate = 1
      this.stateGeneral = 1
      this.toastr.error("escoge imagen", 'Advertencia!', { timeOut: 7000 });
      return;
    }
    if (!this.ITEM_GALLERY_NOW.id && !this.__ModalGalleryItemService.idGallery) {
      this.btnUpdate = 1
      this.stateGeneral = 1
      this.toastr.error("Ocurrió un error, actualiza la página y vuelve a probar", 'Advertencia!', { timeOut: 7000 });
      return;
    }

    const dataEnviar = {
      "id": this.ITEM_GALLERY_NOW.id,
      "idImg": this.ITEM_GALLERY_NOW.idImg,
      "url": this.ITEM_GALLERY_NOW.url,
      "idGallery": this.__ModalGalleryItemService.idGallery,

      "token": this.usersService.token
    }

    this.galleryService.addOrUpdateItem(dataEnviar)
      .subscribe((respuesta: any) => {
        this.btnUpdate = 1
        this.stateGeneral = 1

        if (respuesta.ok) {
          this.galleryService.__refresh()
          this.toastr.success("Guardado", 'Éxito!', { timeOut: 7000 });
          this.closeModal()
          return
        }
        this.toastr.error("ocurrió un error", 'Advertencia!', { timeOut: 7000 });
        this.closeModal()
      });
  }

  getLink(e: any) {
    this.stateToShowSearchRoutes = 0
    this.urlSelected = e
    this.ITEM_GALLERY_NOW.url = e
    setTimeout(() => {
      this.stateToShowSearchRoutes = 1
    }, 300);
  }

  setImg(e: any) {
    this.ITEM_GALLERY_NOW.idImg = e.idImg;
    this.ITEM_GALLERY_NOW.urlImg = URL_IMG + e.urlImg;
  }

  removeImgById() {
    this.ITEM_GALLERY_NOW.idImg = 0;
    this.ITEM_GALLERY_NOW.urlImg = '';
  }

  closeModal() {
    this.nVez = 0
    this.stateGeneral = 0
    this.btnUpdate = 1
    this.stateChooseImg = 0

    this.ITEM_GALLERY_NOW = {
      id: 0,
      idImg: 0,
      urlImg: "",
      url: "",
    }

    this.ERROR_ITEM_GALLERY_NOW = {
      quantityToCount: "",
    }

    this.__ModalGalleryItemService.ocultarModal()
  }
}
