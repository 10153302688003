import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalItemSlideshowService {

  public oculto = "oculto";
  public idComponentInPage = 0;
  public id = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.idComponentInPage = 0;
    this.id = 0;
  }
  mostrarModal(idComponentInPage: number, id = 0) {
    this.oculto = "";
    this.idComponentInPage = idComponentInPage;
    this.id = id;
  }
}
