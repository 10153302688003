<div class="fondo-negro animated fadeIn" [ngClass]="modalUpdateFooterDataService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">

        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 590px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar datos del footer</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div class="row">
                        <div class="col-lg-3 col-mg-6 col-sm-12">
                            <div class="form-group row">
                                <label class="col-12 col-form-label text-left text-bold-600"
                                    for="horizontal-form-4">Color de fondo</label>
                                <div class="col-12">
                                    <input class="form-control" [(ngModel)]="CPI_FOOTER.backgroundColor"
                                        name="backgroundColor" [(colorPicker)]="CPI_FOOTER.backgroundColor"
                                        [style.background]="CPI_FOOTER.backgroundColor" />
                                    <p class="text-danger">{{CPI_FOOTER_ERROR.backgroundColor}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-mg-6 col-sm-12">
                            <div class="form-group row">
                                <label class="col-12 col-form-label text-left text-bold-600"
                                    for="horizontal-form-4">Texto negrita en
                                    títulos</label>
                                <div class="col-12">
                                    <select [(ngModel)]="CPI_FOOTER.boldHeader" name="users-list-role"
                                        class="form-control">
                                        <option value="1">Si</option>
                                        <option value="0">No</option>
                                    </select>

                                    <!-- <p class="text-danger">{{historiaUsuarioERROR.descripcion}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-mg-6 col-sm-12">
                            <div class="form-group row">
                                <label class="col-12 col-form-label text-left text-bold-600"
                                    for="horizontal-form-4">Color de los
                                    títulos</label>
                                <div class="col-12">
                                    <input class="form-control" [(ngModel)]="CPI_FOOTER.colorHeader" name="colorHeader"
                                        [(colorPicker)]="CPI_FOOTER.colorHeader"
                                        [style.background]="CPI_FOOTER.colorHeader" />
                                    <p class="text-danger">{{CPI_FOOTER_ERROR.colorHeader}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-mg-6 col-sm-12">
                            <div class="form-group row">
                                <label class="col-12 col-form-label text-left text-bold-600"
                                    for="horizontal-form-4">Color de texto</label>
                                <div class="col-12">
                                    <input class="form-control" [(ngModel)]="CPI_FOOTER.colorText" name="colorText"
                                        [(colorPicker)]="CPI_FOOTER.colorText"
                                        [style.background]="CPI_FOOTER.colorText" />
                                    <p class="text-danger">{{CPI_FOOTER_ERROR.colorText}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button (click)="updateFooterData()" type="button" class="btn btn-primary btn-lg float-right mt-2"
                        style="padding-top: 2px !important;">Guardar datos</button>

                    <h2 style="text-align: left;margin-top: 60px;margin-bottom: 20px;">Gestión de ítems</h2>

                    <div class="row w-100">
                        <div *ngIf="ITEMS_FOOTER.length<4 || footerItemToAddDATA.id>0" class="col-12 text-left">
                            <div class="row w-50 m-auto bg-white p-2">
                                <p *ngIf="ITEMS_FOOTER.length<4 && footerItemToAddDATA.id===0"
                                    class="font-medium-3 d-block col-12">Agregar ítem</p>
                                <p *ngIf="footerItemToAddDATA.id>0" class="font-medium-3 d-inline-block">
                                    Actualizar ítem</p>
                                <p *ngIf="footerItemToAddDATA.id>0" (click)="selectItemFooter('',1)"
                                    class="font-small-3 text-danger d-inline-block cursor-pointer ml-2">
                                    Quitar selección</p>
                                <div class="col-md-12 ">
                                    <label class="col-form-label text-left font-weight-bold">Header</label>
                                    <input class="form-control" type="text" [(ngModel)]="footerItemToAddDATA.header"
                                        name="header">
                                </div>

                                <p *ngIf="(!footerItemToAddDATA.idImg || +footerItemToAddDATA.idImg===0)&&stateChooseImg===0"
                                    class="cursor-pointer pt-2 text-warning d-inline-block"
                                    (click)="stateChooseImg = 1">
                                    Seleccionar imagen</p>
                                <div *ngIf="(!footerItemToAddDATA.idImg || +footerItemToAddDATA.idImg===0)&&stateChooseImg===1"
                                    class="col-md-12 box-shadow-2 bg-white p-2 mb-3">
                                    <div class="w-100 text-right">
                                        <span (click)="stateChooseImg = 0"
                                            class="text-danger font-large-2 cursor-pointer"
                                            aria-hidden="true">&times;</span>
                                    </div>
                                    <p class="text-left">Busca, selecciona o agrega una imagen</p>
                                    <app-manage-file (emitImageChoosed)="setImg($event,1)"></app-manage-file>
                                </div>
                                <div *ngIf="footerItemToAddDATA.idImg && footerItemToAddDATA.idImg>0" class="col-md-12">
                                    <button type="button" (click)="setImg('',0)" class="btn btn-danger mb-0"
                                        style="position: relative;display: block;margin: auto;left: 145px;top: 25px;">
                                        <i class="fa fa-times"></i>
                                    </button>

                                    <img style="width: 295px;display: block;margin: auto;"
                                        src="{{BASE_URL_IMG}}{{footerItemToAddDATA.urlImg}}">
                                </div>

                                <div class="col-md-12 ">
                                    <label class="col-form-label text-left font-weight-bold">Descripción</label>
                                    <app-rich-text *ngIf="text!=='__update'" [requestData]="requestDataBoxTextHtml"
                                        [mydataTextHtml]="text" (emitOnChange)="getDataBoxTextHtml($event)">
                                    </app-rich-text>
                                </div>

                                <div class="col-md-12 ">
                                    <label class="col-form-label text-left font-weight-bold">Menú</label>
                                    <div class="">
                                        <select [(ngModel)]="footerItemToAddDATA.menuId" name="menuId"
                                            class="form-control {{theme.background}} {{theme.text}}">
                                            <option value="0">No</option>
                                            <option *ngFor="let menu of MENUS" value="{{menu['id']}}">{{menu['name']}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <button *ngIf="ITEMS_FOOTER.length<4 && footerItemToAddDATA.id===0"
                                    (click)="addItemToFooter(1)" type="button"
                                    class="btn btn-primary btn-lg d-auto m-auto"
                                    style="padding-top: 2px !important;margin-top: 10px !important;margin-bottom: 10px !important;">
                                    Agregar ítem</button>
                                <button *ngIf="footerItemToAddDATA.id>0" (click)="updateItemToFooter(0)" type="button"
                                    class="btn btn-primary btn-lg d-auto m-auto"
                                    style="padding-top: 2px !important;margin-top: 10px !important;margin-bottom: 10px !important;">
                                    Actualizar ítem</button>
                            </div>
                        </div>

                        <div *ngFor="let item of ITEMS_FOOTER" class="col-3">

                            <div class="bg-light card text-dark">
                                <div class="card-body">
                                    <button type="button" (click)="removeItemFooter(item['fi_id'])"
                                        class="btn btn-danger mb-0"
                                        style="position: relative;display: inline;margin: auto;left: 45%;">
                                        <i class="fa fa-times"></i>
                                    </button>
                                    <button type="button" (click)="selectItemFooter(item)" class="btn btn-success mb-0"
                                        style="position: relative;display: inline;margin: auto;left: 4%;">
                                        <i class="fa fa-pencil-square-o"></i>
                                    </button>
                                    <h3 class="mt-4 text-center">{{item['fi_header']}}</h3>
                                    <app-rich-text *ngIf="item['fi_text']!==''" [mydataTextHtml]="item['fi_text']"
                                        [mode]="'editor'">
                                    </app-rich-text>
                                    <img *ngIf="item['fi_idImage']>0" src="{{BASE_URL_IMG}}{{item['i_routeImg']}}"
                                        alt="" height="100" width="100"
                                        class="d-block m-auto img-fluid maintenance-img mt-2">
                                    <ul *ngIf="item['itemsMenu']">
                                        <li *ngFor="let itemsMenu of item['itemsMenu']">
                                            {{itemsMenu['name']}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>


    </div>
</div>