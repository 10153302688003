import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageWithTextOverlayService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  __refreshData() {
    this.refreshData.emit();
  }

  getImageWithTextOverlay(data: any) {
    const url = URL_SERVICIOS + "ImageWithTextOverlay_getByIdCIP&idComponentInPage=" + data.idComponentInPage;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  addOrUpdate(data: any) {
    const url = URL_SERVICIOS + "ImageWithTextOverlay_addOrUpdate";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  updateValueImage(data: any) {
    const url = URL_SERVICIOS + "ImageWithTextOverlay_updateValueImage";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  removeImgById(data: any) {
    const url = URL_SERVICIOS + "ImageWithTextOverlay_removeImgById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  deleteComponentComplete(data: any) {
    const url = URL_SERVICIOS + "ImageWithTextOverlay_deleteComponentComplete";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

}
