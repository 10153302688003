<div class="fondo-negro animated fadeIn" [ngClass]="__ModalImageWithTextOverlayService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- UPDATE -->
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 660px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar iwto</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div [hidden]="stateGeneral===0" class="card-body">
                        <div class="row">
                            <label class="col-12 label-control font-medium-5 font-weight-bold mt-2 mb-3 text-left">
                                Fondo de la sección
                            </label>
                        </div>
                        <div class="form-group row">
                            <label
                                class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">Imagen</label>
                            <p *ngIf="stateChooseImg === 0" class="cursor-pointer pt-2 text-warning"
                                (click)="stateChooseImg = 1">
                                Seleccionar imagen</p>
                            <div *ngIf="(!IWTO_NOW.idImg || +IWTO_NOW.idImg===0)&&stateChooseImg===1"
                                class="col-md-9 box-shadow-2 bg-white p-2 mb-3">
                                <div class="w-100 text-right">
                                    <span (click)="stateChooseImg = 0" class="text-danger font-large-2 cursor-pointer"
                                        aria-hidden="true">&times;</span>
                                </div>
                                <p class="text-left">Busca, selecciona o agrega una imagen</p>
                                <app-manage-file (emitImageChoosed)="setImg($event)"></app-manage-file>
                            </div>
                            <div *ngIf="IWTO_NOW.idImg && IWTO_NOW.idImg>0" class="col-md-9">
                                <button type="button" (click)="removeImgById()" class="btn btn-danger mb-0"
                                    style="position: relative;display: block;margin: auto;left: 145px;top: 25px;">
                                    <i class="fa fa-times"></i>
                                </button>

                                <img style="width: 295px;display: block;margin: auto;" src="{{IWTO_NOW.urlImg}}">
                            </div>
                        </div>

                        <div class="form-group row mt-2">
                            <label class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">
                                Color de fondo (Solo si no hay imagen)
                            </label>
                            <div class="col-md-9">
                                <input class="w-100" [(ngModel)]="IWTO_NOW.boxMain_backgroungColor"
                                    name="boxMain_backgroungColor" [(colorPicker)]="IWTO_NOW.boxMain_backgroungColor"
                                    [style.background]="IWTO_NOW.boxMain_backgroungColor" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">
                                Partículas
                            </label>
                            <div class="col-md-9">
                                <select [(ngModel)]="IWTO_NOW.boxMain_backgroundParticles"
                                    name="boxMain_backgroundParticles"
                                    class="form-control {{theme.background}} {{theme.text}}">
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <label class="col-12 label-control font-medium-5 font-weight-bold mt-3 mb-3 text-left">
                                Datos del contenido
                            </label>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-1 font-weight-bold mt-2 text-left">
                                Color de fondo
                            </label>
                            <div class="col-md-9">
                                <input class="w-100" [(ngModel)]="IWTO_NOW.backgroundColorTextBox"
                                    name="backgroundColorTextBox" [(colorPicker)]="IWTO_NOW.backgroundColorTextBox"
                                    [style.background]="IWTO_NOW.backgroundColorTextBox" />
                                <!-- <p *ngIf="IWTO_NOW_ERROR.backgroundColorTextBox" class="text-danger mb-0">
                                    {{IWTO_NOW_ERROR.backgroundColorTextBox}}
                                </p> -->
                            </div>
                        </div>


                        <div class="accordion">
                            <div class="card box-shadow-2">
                                <div class="card-header p-0">
                                    <h2 class="mb-0">
                                        <button
                                            class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                            type="button" (click)="toggleCollapseTitle()">
                                            <h4 class="text-bold-600 m-3">Título</h4>
                                        </button>
                                    </h2>
                                </div>

                                <div class="collapse {{theme.background2}} {{theme.text}} {{collapseTitle}}">
                                    <div class="card-body">
                                        <app-my-rich-text *ngIf="boxMyRichText.display===1"
                                            [colorText]="IWTO_NOW.titleColor" [fontSizeText]="IWTO_NOW.titleFontSize"
                                            [boldText]="IWTO_NOW.titleBoldText" [text]="IWTO_NOW.titleText"
                                            [requestData]="boxMyRichText.requestData"
                                            (emitOnChange)="setTextFromMyRichText($event,'title')">
                                        </app-my-rich-text>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion">
                            <div class="card box-shadow-2">
                                <div class="card-header p-0">
                                    <h2 class="mb-0">
                                        <button
                                            class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                            type="button" (click)="toggleCollapseDescription()">
                                            <h4 class="text-bold-600 m-3">Descripción</h4>
                                        </button>
                                    </h2>
                                </div>

                                <div class="collapse {{theme.background2}} {{theme.text}} {{collapseDescription}}">
                                    <div class="card-body">
                                        <app-my-rich-text *ngIf="boxMyRichText.display===1"
                                            [colorText]="IWTO_NOW.descriptionColor"
                                            [fontSizeText]="IWTO_NOW.descriptionFontSize"
                                            [boldText]="IWTO_NOW.descriptionBoldText" [text]="IWTO_NOW.descriptionText"
                                            [requestData]="boxMyRichText.requestData"
                                            (emitOnChange)="setTextFromMyRichText($event,'description')">
                                        </app-my-rich-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="stateGeneral===0" class="content-wrapper">
                        <i style="position: absolute;top: 170px;left: 50%;margin: -35px 0 0 -35px;"
                            class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>


                </div>
                <div *ngIf="btnUpdate===1" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>

                    <button (click)="eventUpdate()" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>