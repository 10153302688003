<div class="NgxEditor__Wrapper" style="border: unset;">
    <p *ngIf="displayWarningWhiteBG===1"
        style="text-align: left;color: red;padding-left: 5px;font-weight: 500;font-size: 10px;">
        *El texto de color blanco puede parecer oculto en fondos blancos</p>
    <!-- [colorPresets]="colorPresets" -->
    <ngx-editor-menu *ngIf="mode==='all'" class="editorMenu" [colorPresets]="colorPresets" [toolbar]="toolbar"
        [editor]="editor">
    </ngx-editor-menu>
    <ngx-editor [(ngModel)]="html" [editor]="editor" [disabled]="false">
    </ngx-editor>
</div>