<div class="fondo-negro animated fadeIn" [ngClass]="__ModalContactUsService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- UPDATE -->
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 660px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar "contactanos"</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div [hidden]="stateGeneral===0" class="card-body">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Título</label>
                            <div class="col-md-9">
                                <input class="form-control" [(ngModel)]="CONTACT_US_DATA.title" name="title" />
                                <p class="text-danger">{{ERROR_CONTACT_US_DATA.title}}</p>
                            </div>
                        </div>
                        <div class="form-group row mt-1">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Descripción</label>
                            <div class="col-md-9">
                                <textarea maxlength="1495" rows="4" class="form-control"
                                    [(ngModel)]="CONTACT_US_DATA.description" name="description"></textarea>
                                <p class="text-danger">{{ERROR_CONTACT_US_DATA.description}}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-5 label-control font-medium-4 text-left font-weight-bold mt-2">
                                Estilo de diseño
                            </label>
                            <div class="col-md-7">
                                <select [(ngModel)]="CONTACT_US_DATA.style" name="icon1"
                                    class="form-control {{theme.background}} {{theme.text}}">
                                    <ng-container *ngFor="let style of json_style_contact">
                                        <option value="{{style['value']}}">
                                            {{style["name"]}}
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label text-left font-weight-bold">
                                        Teléfono 1</label>
                                    <div class="col-md-12">
                                        <input class="form-control" [(ngModel)]="CONTACT_US_DATA.tel1" name="tel1" />
                                        <p class="text-danger">{{ERROR_CONTACT_US_DATA.tel1}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label text-left font-weight-bold">
                                        Teléfono 2</label>
                                    <div class="col-md-12">
                                        <input class="form-control" [(ngModel)]="CONTACT_US_DATA.tel2" name="tel2" />
                                        <p class="text-danger">{{ERROR_CONTACT_US_DATA.tel2}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label text-left font-weight-bold">
                                        Teléfono 3</label>
                                    <div class="col-md-12">
                                        <input class="form-control" [(ngModel)]="CONTACT_US_DATA.tel3" name="tel3" />
                                        <p class="text-danger">{{ERROR_CONTACT_US_DATA.tel3}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label text-left font-weight-bold">
                                        E-mail 1</label>
                                    <div class="col-md-12">
                                        <input class="form-control" [(ngModel)]="CONTACT_US_DATA.mail1" name="mail1" />
                                        <p class="text-danger">{{ERROR_CONTACT_US_DATA.mail1}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label text-left font-weight-bold">
                                        E-mail 2</label>
                                    <div class="col-md-12">
                                        <input class="form-control" [(ngModel)]="CONTACT_US_DATA.mail2" name="mail2" />
                                        <p class="text-danger">{{ERROR_CONTACT_US_DATA.mail2}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label text-left font-weight-bold">
                                        E-mail 3</label>
                                    <div class="col-md-12">
                                        <input class="form-control" [(ngModel)]="CONTACT_US_DATA.mail3" name="mail3" />
                                        <p class="text-danger">{{ERROR_CONTACT_US_DATA.mail3}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-1">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Dirección 1</label>
                            <div class="col-md-9">
                                <textarea maxlength="1495" rows="4" class="form-control"
                                    [(ngModel)]="CONTACT_US_DATA.address1" name="address1"></textarea>
                                <p class="text-danger">{{ERROR_CONTACT_US_DATA.address1}}</p>
                            </div>
                        </div>

                        <div class="form-group row mt-1">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Dirección 2</label>
                            <div class="col-md-9">
                                <textarea maxlength="1495" rows="4" class="form-control"
                                    [(ngModel)]="CONTACT_US_DATA.address2" name="address2"></textarea>
                                <p class="text-danger">{{ERROR_CONTACT_US_DATA.address2}}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label text-left font-weight-bold">
                                        Hora de operación 1</label>
                                    <div class="col-md-12">
                                        <input class="form-control" [(ngModel)]="CONTACT_US_DATA.hourOfOperation1"
                                            name="hourOfOperation1" />
                                        <p class="text-danger">{{ERROR_CONTACT_US_DATA.hourOfOperation1}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label text-left font-weight-bold">
                                        Hora de operación 2</label>
                                    <div class="col-md-12">
                                        <input class="form-control" [(ngModel)]="CONTACT_US_DATA.hourOfOperation2"
                                            name="hourOfOperation2" />
                                        <p class="text-danger">{{ERROR_CONTACT_US_DATA.hourOfOperation2}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label text-left font-weight-bold">
                                        Hora de operación 3</label>
                                    <div class="col-md-12">
                                        <input class="form-control" [(ngModel)]="CONTACT_US_DATA.hourOfOperation3"
                                            name="hourOfOperation3" />
                                        <p class="text-danger">{{ERROR_CONTACT_US_DATA.hourOfOperation3}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div *ngIf="stateGeneral===0" class="content-wrapper">
                        <i style="position: absolute;top: 170px;left: 50%;margin: -35px 0 0 -35px;"
                            class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>


                </div>
                <div *ngIf="btnUpdate===1" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>

                    <button (click)="eventUpdate()" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>