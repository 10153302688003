<div *ngIf="styleToUse==='height'" class="w-100 row">
    <div class="col-4">
        <p>Valor</p>
        <input type="number" min="0" [(ngModel)]="_forHeight.value" name="value" class="d-block w-100"
            (change)="_forHeight_makingOutput()">
    </div>
    <div class="col-3">
        <p>Medida</p>
        <select [(ngModel)]="_forHeight.measure" (change)="_forHeight_makingOutput()" name="measure"
            class="form-control w-100">
            <option *ngFor="let comp of JsonMeasure" value="{{comp['value']}}">
                {{comp['name']}}
            </option>
        </select>
    </div>
    <div class="col-5">
        <p>Resultado</p>
        <p>{{_forHeight.outPut}}</p>
    </div>
</div>