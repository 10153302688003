import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { validateNumberRangeToChoose } from 'src/app/functions/func_general';
import { Measures } from 'src/app/jsons/JSON_GENERAL';

@Component({
  selector: 'app-manage-styles',
  templateUrl: './manage-styles.component.html',
  styles: [
  ]
})
export class ManageStylesComponent implements OnInit {

  @Output() emitOnChange = new EventEmitter<any>();
  @Input() styleToUse = '';
  @Input() valueFromDb = '';

  JsonMeasure = Measures

  _forHeight = {
    value: 0,
    outPut: '',
    measure: 'px',
  };

  constructor() { }

  ngOnInit(): void {

    if (this.styleToUse === 'height') {
      if (this.valueFromDb && this.valueFromDb !== 'auto') {
        this._forHeight.value = +this.valueFromDb.replace(/px|em|%/g, '')
        if (this.valueFromDb.includes("px")) this._forHeight.measure = 'px';
        if (this.valueFromDb.includes("%")) this._forHeight.measure = '%';
        if (this.valueFromDb.includes("em")) this._forHeight.measure = 'em';
      } else if (this.valueFromDb && this.valueFromDb == 'auto') {
        this._forHeight.measure = 'auto'
      }
      this._forHeight_makingOutput();
    }
  }

  _forHeight_makingOutput() {

    this._forHeight.value = validateNumberRangeToChoose(this._forHeight.value, 0, 2000)
    if (this._forHeight.measure === 'auto') {
      this._forHeight.outPut = 'auto';
      this.emitOnChange.emit(this._forHeight.outPut);
      return;
    }
    this._forHeight.outPut = this._forHeight.value + this._forHeight.measure;
    this.emitOnChange.emit(this._forHeight.outPut);
  }

}
