<div *ngIf="type==='image'" class="row">
    <div cdkDropList class="col-7 m-auto" (cdkDropListDropped)="drop($event)">
        <div class="example-box"
            style="margin: 20px 0px;border-top: 1px solid #ccc;border-bottom: 1px solid #ccc;cursor: grab;"
            *ngFor="let n of data" cdkDrag>
            <img class="w-100 d-block m-auto" src="../../../assets/img/forComponents/{{n['c_urlImage']}}">
            <span>{{n[keyToShow]}}</span>
        </div>
    </div>
</div>

<div *ngIf="type==='text-horizontal'" class="row w-100">
    <div cdkDropList cdkDropListOrientation="horizontal" style="width: 1000px;
    max-width: 100%;border: solid 1px #ccc;min-height: 60px;display: flex;flex-direction: row;background: white;
    border-radius: 4px;overflow: hidden;" (cdkDropListDropped)="drop($event)">
        <div class="example-box text-left" style="padding: 20px 10px;border: 1px solid #ccc;cursor: grab;width: 130px;"
            *ngFor="let n of data" cdkDrag>
            <i class="fa fa-th mr-2" aria-hidden="true"></i>
            <span>{{n[keyToShow]}}</span>
        </div>
    </div>
</div>

<div *ngIf="type==='text'" class="row">
    <div cdkDropList class="col-12 m-auto" (cdkDropListDropped)="drop($event)">
        <div class="example-box text-left"
            style="margin: 20px 0px;border-top: 1px solid #ccc;border-bottom: 1px solid #ccc;cursor: grab;"
            *ngFor="let n of data" cdkDrag>
            <i class="fa fa-th mr-3" aria-hidden="true"></i>
            <span>{{n[keyToShow]}}</span>
        </div>
    </div>
</div>