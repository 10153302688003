import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalItemCounterService {

  public oculto = "oculto";
  public idSectionCounter = 0;
  public id = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.idSectionCounter = 0;
  }
  mostrarModal(idSectionCounter: number, id: number = 0) {
    this.oculto = "";
    this.idSectionCounter = idSectionCounter;
    this.id = id;
  }
}
