<div class="fondo-negro animated fadeIn" *ngIf="modalItemOurPricesService.oculto!=='oculto'">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- UPDATE -->
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 660px;">
                <div class="modal-header">
                    <h2 *ngIf="modalItemOurPricesService.id > 0 && modalItemOurPricesService.idCFM === 0"
                        class="modal-title">Actualizar Ítem de "nuestros precios"</h2>
                    <h2 *ngIf="modalItemOurPricesService.idCFM > 0 && modalItemOurPricesService.id === 0"
                        class="modal-title">Reordenar Ítem de "nuestros precios"</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div *ngIf="modalItemOurPricesService.idCFM > 0 && modalItemOurPricesService.id === 0"
                        class="card-body">
                        <app-drag-and-drop *ngIf="stateGeneral===1 && ARRAY_TO_SORT.length>0"
                            (emitNewDataOrdered)="changeOrderPosition($event)" [data]="ARRAY_TO_SORT"
                            [config]="{id: 'cip_id',nameKeyToShow: 'c_name'}" [type]="'text-horizontal'"
                            [keyToShow]="'title'">
                        </app-drag-and-drop>
                        <p *ngIf="stateGeneral===1 && ARRAY_TO_SORT.length===0">No hay datos</p>
                    </div>

                    <div *ngIf="modalItemOurPricesService.id > 0 && modalItemOurPricesService.idCFM === 0"
                        [hidden]="stateGeneral===0" class="card-body">

                        <div class="form-group row">
                            <label class="col-md-5 label-control font-medium-4 text-left font-weight-bold mt-2">
                                Ícono referencial
                            </label>
                            <div class="col-md-7">
                                <select [(ngModel)]="ITEM_DATA.icon1" name="icon1"
                                    class="form-control {{theme.background}} {{theme.text}}">
                                    <option value="">No usar ícono</option>
                                    <ng-container *ngFor="let icon of confIcons">
                                        <option value="{{icon['value']}}">
                                            {{icon["name"]}}
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                            <label class="col-md-12">
                                <i class="{{ITEM_DATA['icon1']}} font-large-3" aria-hidden="true"></i>
                            </label>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-4 text-left font-weight-bold mt-2">
                                Título
                            </label>
                            <div class="col-md-9">
                                <input class="w-100 form-control {{theme.background}} {{theme.text}}"
                                    [(ngModel)]="ITEM_DATA.title" name="title" />
                                <p class="text-danger">{{ERROR_ITEM_DATA.title}}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="col-12 col-form-label text-left font-weight-bold">Descripción</label>
                            <app-rich-text [requestData]="requestDataBoxTextHtml" [mydataTextHtml]="text"
                                (emitOnChange)="getDataBoxTextHtml($event,'title')"></app-rich-text>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-md-5 label-control font-medium-4 text-left font-weight-bold mt-2">
                                        Precio
                                    </label>
                                    <div class="col-md-7">
                                        <input class="w-100 form-control {{theme.background}} {{theme.text}}"
                                            [(ngModel)]="ITEM_DATA.price" name="price" />
                                        <p class="text-danger">{{ERROR_ITEM_DATA.price}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-md-5 label-control font-medium-4 text-left font-weight-bold mt-2">
                                        Precio para comparar
                                    </label>
                                    <div class="col-md-7">
                                        <input class="w-100 form-control {{theme.background}} {{theme.text}}"
                                            [(ngModel)]="ITEM_DATA.comparePrice" name="comparePrice" />
                                        <p class="text-danger">{{ERROR_ITEM_DATA.comparePrice}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-md-5 label-control font-medium-4 text-left font-weight-bold mt-2">
                                        Prefijo del precio
                                    </label>
                                    <div class="col-md-7">
                                        <input class="w-100 form-control {{theme.background}} {{theme.text}}"
                                            [(ngModel)]="ITEM_DATA.prefijo" name="prefijo" />
                                        <p class="text-danger">{{ERROR_ITEM_DATA.prefijo}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-md-5 label-control font-medium-4 text-left font-weight-bold mt-2">
                                        Postfijo del precio
                                    </label>
                                    <div class="col-md-7">
                                        <input class="w-100 form-control {{theme.background}} {{theme.text}} "
                                            [(ngModel)]="ITEM_DATA.postfijo" name="postfijo" />
                                        <p class="text-danger">{{ERROR_ITEM_DATA.postfijo}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-md-5 label-control font-medium-4 text-left font-weight-bold mt-2">
                                        Color de fondo
                                    </label>
                                    <div class="col-md-7">
                                        <input class="w-100" [(ngModel)]="ITEM_DATA.backgroundColor"
                                            name="backgroundColor" [(colorPicker)]="ITEM_DATA.backgroundColor"
                                            [style.background]="ITEM_DATA.backgroundColor" />
                                        <p class="text-danger">{{ERROR_ITEM_DATA.backgroundColor}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-md-5 label-control font-medium-4 text-left font-weight-bold mt-2">
                                        Color del texto
                                    </label>
                                    <div class="col-md-7">
                                        <input class="w-100" [(ngModel)]="ITEM_DATA.colorText" name="colorText"
                                            [(colorPicker)]="ITEM_DATA.colorText"
                                            [style.background]="ITEM_DATA.colorText" />
                                        <p class="text-danger">{{ERROR_ITEM_DATA.colorText}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label class="col-md-5 label-control font-medium-4 text-left font-weight-bold mt-2">
                                        Mostrar precio
                                    </label>
                                    <div class="col-md-7">
                                        <select [(ngModel)]="ITEM_DATA.displayPrice" name="displayPrice"
                                            class="form-control {{theme.background}} {{theme.text}}">
                                            <option value="0">No</option>
                                            <option value="1">Si</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion">
                            <div class="card box-shadow-2">
                                <div class="card-header p-0">
                                    <h2 class="mb-0">
                                        <button
                                            class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                            type="button" (click)="toggleCollapseButtonAction()">
                                            <h4 class="text-bold-600 m-3">Botón de acción</h4>
                                        </button>
                                    </h2>
                                </div>

                                <div class="collapse {{theme.background2}} {{theme.text}} {{collapseButtonAction}}">
                                    <div class="card-body">
                                        <app-my-rich-text *ngIf="boxMyRichText.display===1"
                                            [colorText]="ITEM_DATA.buttonAction.colorText"
                                            [fontSizeText]="ITEM_DATA.buttonAction.fontSize"
                                            [boldText]="ITEM_DATA.buttonAction.boldText"
                                            [text]="ITEM_DATA.buttonAction.text"
                                            [requestData]="boxMyRichText.requestData"
                                            [border]="ITEM_DATA.buttonAction.border"
                                            [backgroundColor]="ITEM_DATA.buttonAction.backgroundColor"
                                            [urlSelected]="ITEM_DATA.buttonAction.url"
                                            [align]="ITEM_DATA.buttonAction.align" [renderFor]="'button'"
                                            (emitOnChange)="getDataBoxTextHtml($event,'button')">
                                        </app-my-rich-text>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="stateGeneral===0" class="content-wrapper">
                        <i style="position: absolute;top: 170px;left: 50%;margin: -35px 0 0 -35px;"
                            class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>


                </div>
                <div *ngIf="btnUpdate===1 && modalItemOurPricesService.idCFM === 0" class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>

                    <button (click)="update(1)" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>