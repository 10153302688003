import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/services/product.service';
import { UsersService } from 'src/app/services/users.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { ModalEntryService } from './modal-entry.service';
import { URL_IMG } from 'src/app/config/config';

@Component({
  selector: 'app-modal-entry',
  templateUrl: './modal-entry.component.html',
  styles: [
  ]
})
export class ModalEntryComponent implements DoCheck {
  urlGeneralImg = URL_IMG
  lastSearch: string = ""

  nVez = 0;
  stateToShow = 0
  stateTableToShow = 0

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  PROVIDERS = []

  ENTRY_DATA =
    {
      "id": 0,
      "token": "",
      "nameProduct": "",
      "nameMeasure": "",
      "variantMeasure": "",
      "idVariant": 0,
      "idProduct": 0,
      "idProvider": 0,
      "idUserCreator": 0,
      "quantity": 0,
    };

  ENTRY_DATA_ERROR =
    {
      "idProduct": "",
      "idProvider": "",
      "idVariant": "",
      "quantity": "",
    };

  PRODUCT_DATA = [];

  // PARA PAGINACIÓN
  pagination = {
    "totalPaginas": 0,
    "totalData": 0,
    "actual": 1,
    "anterior": false,
    "siguiente": false
  }

  offset = 0;
  limit = 50;
  // Fin para paginación

  constructor(
    public modalEntryService: ModalEntryService,
    public usuarioService: UsersService,
    public warehouseService: WarehouseService,
    // public __ProviderService: ProviderService,
    private toastr: ToastrService,
    public productService: ProductService,
  ) {
    // this.theme = this._SettingsService.loadThemeFromStorage()
  }

  ngDoCheck() {
    if (this.modalEntryService.oculto == "" && this.nVez == 0) {
      this.nVez = 1;
      this.cargarDatos();
    }
  }

  cargarDatos() {
    // this._Cargando.mostrarAnimacion();
    // this.stateToShow = 0
    // this.getAllProviders().then(
    //   () => {
    //     this.stateToShow = 1
    //   }
    // );
  }

  setProduct(i: any) {
    // return
    this.ENTRY_DATA.variantMeasure = i.V_name
    this.ENTRY_DATA.idVariant = i.V_id
    this.ENTRY_DATA.idProduct = i.P_id
    this.ENTRY_DATA.nameProduct = i.P_name
    this.stateTableToShow = 0
  }

  cerrarModal() {
    this.lastSearch = "";

    this.nVez = 0;
    this.stateToShow = 0
    this.stateTableToShow = 0
    this.PROVIDERS = []

    this.ENTRY_DATA =
    {
      "id": 0,
      "token": "",
      "nameProduct": "",
      "nameMeasure": "",
      "variantMeasure": "",
      "idProduct": 0,
      "idVariant": 0,
      "idProvider": 0,
      "idUserCreator": 0,
      "quantity": 0,
    };

    this.ENTRY_DATA_ERROR =
    {
      "idProduct": "",
      "idProvider": "",
      "idVariant": "",
      "quantity": "",
    };

    this.PRODUCT_DATA = [];

    this.modalEntryService.ocultarModal();
  }


  addEntry() {

    if (!this.validarCampo("idProduct") || !this.validarCampo("idVariant") || !this.validarCampo("quantity")) {
      return;
    }

    this.ENTRY_DATA.token = this.usuarioService.token;
    (document.getElementById("btn_add_entry") as HTMLInputElement).style.display = "none";

    this.warehouseService.addEntry(this.ENTRY_DATA)
      .subscribe((resp2: any) => {
        (document.getElementById("btn_add_entry") as HTMLInputElement).style.display = "block";
        if (resp2.ok) {
          this.toastr.success("", 'Entrada agregada', { timeOut: 5000 });
          this.warehouseService.__refresh();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 5000 });
        }
      });
  }

  searchVariantProduct(busqueda: string, desdeInicio = 0) {
    if (this.lastSearch == busqueda) {
      return;
    }
    this.lastSearch = busqueda

    if (desdeInicio == 1) {
      this.offset = 0;
      this.limit = 10;
      this.pagination = {
        "totalPaginas": 0,
        "totalData": 0,
        "actual": 1,
        "anterior": false,
        "siguiente": false
      }
    }

    const dataEnviar = {
      "offset": this.offset,
      "limit": this.limit,
      "wordToFind": busqueda,
      "token": this.usuarioService.token
    }
    this.stateTableToShow = 0
    this.productService.searchVariantProduct(dataEnviar)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {

          this.PRODUCT_DATA = respuesta.data;

          // this.calcularPaginacion(respuesta)
          this.stateTableToShow = 1
          return;
        }
        this.PRODUCT_DATA = [];
        this.stateTableToShow = 1
      });
  }

  validarCampo(campo: any) {

    if (campo == "idProduct") {
      if (!this.ENTRY_DATA.idProduct || this.ENTRY_DATA.idProduct == 0) {
        this.ENTRY_DATA_ERROR.idProduct = "Este campo no puede estar vacío";
        return false;
      } else {
        this.limpiar();
      }
    }

    if (campo == "idVariant") {
      if (!this.ENTRY_DATA.idVariant || this.ENTRY_DATA.idVariant == 0) {
        this.ENTRY_DATA_ERROR.idVariant = "Este campo no puede estar vacío, escoge un producto!";
        return false;
      } else {
        this.limpiar();
      }
    }

    if (campo == "quantity") {
      if (!this.ENTRY_DATA.quantity) {
        this.ENTRY_DATA_ERROR.quantity = "Este campo no puede estar vacío";
        return false;
      } else if (this.ENTRY_DATA.quantity == 0) {
        this.ENTRY_DATA_ERROR.quantity = "Este campo tiene que ser mayor a 0";
        return false;
      } else {
        this.limpiar();
      }
    }
    return true;
  }

  limpiar() {
    this.ENTRY_DATA_ERROR =
    {
      "idProduct": "",
      "idProvider": "",
      "idVariant": "",
      "quantity": "",
    };
  }
}
