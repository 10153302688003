import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OptionService } from 'src/app/services/option.service';
import { ProductService } from 'src/app/services/product.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalVariantService } from './modal-variant.service';
import { URL_IMG } from 'src/app/config/config';

@Component({
  selector: 'app-modal-variant',
  templateUrl: './modal-variant.component.html',
  styles: [
  ]
})
export class ModalVariantComponent implements DoCheck {
  urlGeneralImg = URL_IMG
  nVez = 0

  OPTIONS_VARIANT = []

  DATA = {
    "id": 0,
    "idProduct": 0,
    "name": "Default",
    "key1": "",
    "idTypeVariant": 3,
    "price": 0,
    "priceCompare": 0,
    "i_id": 0,
    "i_route": "",
    "token": "",
  }

  DATA_ERROR = {
    "id": "",
    "name": "",
    "idTypeVariant": "",
    "price": "",
    "key1": "",
    "priceCompare": "",
    "token": "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    public productService: ProductService,
    private toastr: ToastrService,
    public modalVariantService: ModalVariantService,
    public optionService: OptionService,
  ) { }

  ngDoCheck() {
    if (this.modalVariantService.oculto === "" && this.nVez === 0 && this.modalVariantService.idProduct > 0) {
      this.DATA.idProduct = this.modalVariantService.idProduct
      this.cargarDatos();
      this.nVez = 1;
    }
  }

  cargarDatos() {
    // this._Cargando.mostrarAnimacion();
    this.getOptionsVariant().then(
      () => {
        if (this.modalVariantService.id > 0) {
          this.getVariantById().then(
            () => {
            }
          );
        }
      }
    );
  }

  updateVariant() {

    if (!this.validarCampo("name") || !this.validarCampo("price") || !this.validarCampo("priceCompare")) {
      return
    }

    this.DATA.id = this.modalVariantService.id;
    this.DATA.token = this.usersService.token;
    this.productService.updateVariant(this.DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Variante actualizada con éxito.", 'Éxito!', { timeOut: 4000 });
          this.productService.__refresh()
          this.cerrarModal()
        } else {
          this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  getOptionsVariant(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "token": this.usersService.token,
      }

      this.optionService.getVariantType(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.OPTIONS_VARIANT = respuesta.data
            resolve(true);
            return;
          }
          this.OPTIONS_VARIANT = []
          resolve(true);
        });
    })
  }

  getVariantById(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "token": this.usersService.token,
        "idVariant": this.modalVariantService.id,
      }

      this.productService.getVariantsById(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.DATA.id = respuesta.data[0].id;
            this.DATA.name = respuesta.data[0].name;
            this.DATA.idTypeVariant = respuesta.data[0].idTypeVariant;
            this.DATA.price = parseFloat(respuesta.data[0].price);
            this.DATA.priceCompare = parseFloat(respuesta.data[0].priceCompare);
            this.DATA.i_id = respuesta.data[0].i_id;
            this.DATA.i_route = respuesta.data[0].i_route;
            this.DATA.key1 = respuesta.data[0].key1;
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  onChangeTypeVarian() {
    if (this.DATA.idTypeVariant === 3) {
      this.DATA.name = "Default"
      return;
    }
  }

  removeVariantImgById(idImg: number, idVariant: number) {
    const dataEnviar = {
      "idImg": idImg,
      "idVariant": idVariant,
      "token": this.usersService.token
    }

    this.productService.removeVariantImgById(dataEnviar)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.toastr.success("Imagen eliminada", 'Éxito!', { timeOut: 7000 });
          this.cargarDatos();
          return
        }
        this.toastr.error("Error", 'Advertencia!', { timeOut: 7000 });
      });
  }

  addVariant() {

    if (!this.validarCampo("name") || !this.validarCampo("price") || !this.validarCampo("priceCompare")) {
      return
    }

    this.DATA.token = this.usersService.token;
    this.productService.addVariant(this.DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Variante agregada con éxito.", 'Éxito!', { timeOut: 4000 });
          this.productService.__refresh()
          this.cerrarModal()
        } else {
          this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  cerrarModal() {
    this.nVez = 0

    this.OPTIONS_VARIANT = []

    this.DATA = {
      "id": 0,
      "idProduct": 0,
      "name": "Default",
      "idTypeVariant": 3,
      "key1": "",
      "price": 0,
      "priceCompare": 0,
      "i_id": 0,
      "i_route": "",
      "token": "",
    }

    this.DATA_ERROR = {
      "id": "",
      "name": "",
      "idTypeVariant": "",
      "price": "",
      "priceCompare": "",
      "key1": "",
      "token": "",
    }
    this.modalVariantService.ocultarModal()
  }

  validarCampo(wich: string) {
    this.limpiar();
    if (wich === "price") {
      if (typeof this.DATA.price === 'number' && this.DATA.price >= 0) {
        return true;
      } else {
        this.DATA.price = 0
        this.DATA_ERROR.price = "Este campo necesita ser numérico y como mínimo 0";
        return false
      }
    }
    this.limpiar();

    if (wich === "priceCompare") {
      if (typeof this.DATA.priceCompare === 'number' && this.DATA.priceCompare >= 0) {
        return true;
      } else {
        this.DATA.priceCompare = 0
        this.DATA_ERROR.priceCompare = "Este campo necesita ser numérico y como mínimo 0";
        return false
      }
    }
    this.limpiar();

    if (wich === "name") {
      if ((this.DATA.name).length > 150) {
        this.DATA_ERROR.name = "Este campo debe tener menos de 150 caracteres";
        return false;
      } else if (this.DATA.name === "") {
        this.DATA_ERROR.name = "Este campo no puede estar vacío";
        return false;
      } else {
        return true
      }
    }
    this.limpiar();
    return true;
  }

  limpiar() {
    this.DATA_ERROR = {
      "id": "",
      "name": "",
      "idTypeVariant": "",
      "price": "",
      "priceCompare": "",
      "key1": "",
      "token": "",
    }
  }

  onSelect(event: any) {

    const elemento = event.target;

    if (elemento.value.split('.')[1] !== "png" && elemento.value.split('.')[1] !== "jpg" && elemento.value.split('.')[1] !== "jpeg" && elemento.value.split('.')[1] !== "JPG") {
      // swal.fire("Remember that you can only upload images of type png, jpg and jpeg", "", "error");
      this.toastr.error("Solo se permiten los formatos: png, jpg and jpeg", 'Advertencia!', { timeOut: 5000 });

      (document.getElementById("fileUpload_IMAGEN") as HTMLInputElement).value = "";
      return;
    }

    if (elemento.files.length > 0) {

      if ((elemento.files[0].size / 1000) > 450) {
        this.toastr.error("Solo se permite imagenes con tamaño menor a 450 Kilobytes", 'Advertencia!', { timeOut: 5000 });

        (document.getElementById("fileUpload_IMAGEN") as HTMLInputElement).value = "";
        return;
      }

      const formData = new FormData();
      formData.append('token', this.usersService.token);
      formData.append('id', "" + this.modalVariantService.id);

      formData.append('file', elemento.files[0]);

      this.productService.variant_uploadImage(formData)
        .subscribe((data: any) => {
          if (data.ok) {
            this.toastr.success("Se agregó la imagen correctamente", 'Éxito!', { timeOut: 6000 });
          } else {
            this.toastr.error("OCURRIÓ UN ERROR, POR FAVOR VUELVE A ACTUALIZAR", 'Advertencia!', { timeOut: 6000 });
          }
          this.productService.__refresh();
          (document.getElementById("fileUpload_IMAGEN") as HTMLInputElement).value = "";
          this.cerrarModal();
        });
      return;



    }
  }
}
