<div [style.display]="(stateToShowChatBox===1)?'block':'none'" class="chatIncident_boxGeneral1">
    <div (click)='stateToShowChatBox=0' #containerMinus class="text-right mb-2"></div>
    <div class="w-100">
        <h4 *ngIf="view==='FORM_IDENTIFY'" class="font-weight-bold font-small-5 text-white text-center">Registra tu
            incidencia</h4>
        <p *ngIf="view==='FORM_IDENTIFY'" class="text-white">
            Por favor ingresa los siguientes datos:
        </p>

        <div *ngIf="view==='FORM_IDENTIFY'" class="card-body" style="overflow: scroll;max-height: 345px;">
            <div class="form-group row">
                <label class="col-md-12 col-form-label text-left font-weight-bold text-white">DNI</label>
                <div class="col-md-12">
                    <input class="form-control" [(ngModel)]="INCIDENT_OBJECT.dni" name="dni" placeholder="dni"
                        autocomplete="off" type="number" />
                    <p class="text-danger">{{ERROR_INCIDENT_OBJECT.dni}}</p>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-12 col-form-label text-left font-weight-bold text-white">Teléfono</label>
                <div class="col-md-12">
                    <input class="form-control" [(ngModel)]="INCIDENT_OBJECT.telephone" name="telephone"
                        placeholder="telephone" autocomplete="off" type="number" />
                    <p class="text-danger">{{ERROR_INCIDENT_OBJECT.telephone}}</p>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-12 col-form-label text-left font-weight-bold text-white">Email</label>
                <div class="col-md-12">
                    <input class="form-control" [(ngModel)]="INCIDENT_OBJECT.email" name="email" placeholder="email"
                        autocomplete="off" type="text" />
                    <p class="text-danger">{{ERROR_INCIDENT_OBJECT.email}}</p>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-12 col-form-label text-left font-weight-bold text-white">
                    Detalla la incidencia</label>
                <div class="col-md-12">
                    <textarea name="message" [(ngModel)]="INCIDENT_OBJECT.message" class="w-100" cols="10"
                        rows="5"></textarea>
                    <p class="text-danger">{{ERROR_INCIDENT_OBJECT.message}}</p>
                </div>
            </div>
            <div class="form-group row mt-2">
                <button (click)="sendIncident()" type="button" class="btn btn-success w-100">Enviar</button>
            </div>
        </div>
    </div>
</div>
<div (click)="toggleDisplayChat()" class="cursor-pointer" #dataContainer style="
position: fixed;right: 15px;bottom: 20px;width: 60px;height: 60px;
background-color: #0e6d0e;display: flex;justify-content: center;
align-items: center;border-radius: 50%;">
</div>