import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CipFooterService } from 'src/app/services/cfm-footer.service';
import { UsersService } from 'src/app/services/users.service';
import { URL_IMG } from 'src/app/config/config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'
  ]
})
export class FooterComponent implements OnInit {

  // @Input() page = "";
  @Input() vista = "COMPLETE";
  BASE_URL_IMG = URL_IMG;

  ITEMS_FOOTER = []

  CPI_FOOTER = {
    id: "",
    urlImage: "",
    backgroundColor: "",
    boldHeader: "",
    colorHeader: "",
    colorText: "",
    idImage: "",
  }

  constructor(
    public _UsersService: UsersService,
    public cipFooterService: CipFooterService,
  ) { }

  ngOnInit(): void {
    this.getFooterDataForStore().then(
      () => {
        this.getItemsByFooterId().then(
          () => {
            this.setDisable().then(
              () => {
              }
            );

          }
        );
      }
    );
  }

  setDisable(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let list, index;
        list = document.getElementsByClassName("ProseMirror");
        for (index = 0; index < list.length; ++index) {
          list[index].setAttribute("contenteditable", "false")
        }
        resolve(true);
      }, 700);
    })
  }

  getFooterDataForStore(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "ipClient": "",
      }

      this.cipFooterService.getFooterDataForStore(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.CPI_FOOTER.id = respuesta.data_array.CIPF_id
            this.CPI_FOOTER.backgroundColor = respuesta.data_array.CIPF_backgroundColor
            this.CPI_FOOTER.boldHeader = respuesta.data_array.CIPF_boldHeader
            this.CPI_FOOTER.colorHeader = respuesta.data_array.CIPF_colorHeader
            this.CPI_FOOTER.colorText = respuesta.data_array.CIPF_colorText
            resolve(true);
            return;
          }

        });
      return
    })
  }

  getItemsByFooterId(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this.cipFooterService.getItemsByFooterId({ idFooter: this.CPI_FOOTER.id })
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.ITEMS_FOOTER = respuesta.data_array;
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

}
