<div *ngIf="__ModalGalleryItemService.oculto===''" class="fondo-negro animated fadeIn">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!-- UPDATE -->
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 660px;">
                <div class="modal-header">
                    <h2 class="modal-title">Actualizar "imagen con texto"</h2>

                    <button (click)="closeModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div [hidden]="stateGeneral===0" class="card-body">

                        <div class="form-group row">
                            <label class="col-md-3 label-control font-medium-4 font-weight-bold mt-2">Imagen</label>

                            <p *ngIf="stateChooseImg === 0" class="cursor-pointer pt-2 text-warning"
                                (click)="stateChooseImg = 1">
                                Seleccionar imagen</p>
                            <div *ngIf="(!ITEM_GALLERY_NOW.idImg || +ITEM_GALLERY_NOW.idImg===0)&&stateChooseImg===1"
                                class="col-md-9 box-shadow-2 bg-white p-2 mb-3">
                                <div class="w-100 text-right">
                                    <span (click)="stateChooseImg = 0" class="text-danger font-large-2 cursor-pointer"
                                        aria-hidden="true">&times;</span>
                                </div>
                                <p class="text-left">Busca, selecciona o agrega una imagen</p>
                                <app-manage-file (emitImageChoosed)="setImg($event)"></app-manage-file>
                            </div>
                            <div *ngIf="ITEM_GALLERY_NOW.idImg && ITEM_GALLERY_NOW.idImg>0" class="col-md-9">
                                <button type="button" (click)="removeImgById()" class="btn btn-danger mb-0"
                                    style="position: relative;display: block;margin: auto;left: 145px;top: 25px;">
                                    <i class="fa fa-times"></i>
                                </button>

                                <img style="width: 295px;display: block;margin: auto;"
                                    src="{{ITEM_GALLERY_NOW.urlImg}}">
                            </div>
                        </div>

                        <div class="form-group row mt-1">
                            <label class="col-md-3 col-form-label">Enlace</label>
                            <div class="col-md-9">
                                <app-search-routes *ngIf="stateToShowSearchRoutes===1" (emitOnChange)="getLink($event)">
                                </app-search-routes>
                                <!-- <input class="form-control" [(ngModel)]="MENU_ITEM_DATA.url" name="url" />
                                <p class="text-danger">{{MENU_ITEM_DATA_ERROR.url}}</p> -->
                            </div>
                        </div>

                    </div>
                    <div *ngIf="stateGeneral===0" class="content-wrapper">
                        <i style="position: absolute;top: 170px;left: 50%;margin: -35px 0 0 -35px;"
                            class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>


                </div>
                <div *ngIf="btnUpdate===1" class="modal-footer">
                    <button (click)="closeModal()" type="button" class="btn btn-danger">cerrar</button>

                    <button (click)="eventUpdate()" type="button" class="btn btn-primary">Actualizar</button>
                </div>
            </div>

        </div>
    </div>
</div>