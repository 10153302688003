import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { json_style_contact } from 'src/app/jsons/jsonStyleContact';
import { ContactUsService } from 'src/app/services/contact-us.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalContactUsService } from './modal-contact-us.service';

@Component({
  selector: 'app-modal-contact-us',
  templateUrl: './modal-contact-us.component.html',
  styles: [
  ]
})
export class ModalContactUsComponent implements DoCheck {

  nVez = 0
  stateGeneral = 0
  btnUpdate = 1

  json_style_contact = json_style_contact

  CONTACT_US_DATA = {
    id: 0,
    idCFM: 0,
    title: "",

    description: "",
    tel1: "",
    tel2: "",
    tel3: "",

    imgId_qrWhatsapp: 0,
    urlImg: "",

    mail1: "",
    mail2: "",
    mail3: "",
    address1: "",
    address2: "",
    hourOfOperation1: "",
    hourOfOperation2: "",
    hourOfOperation3: "",

    style: 1,

    token: "",
  }

  ERROR_CONTACT_US_DATA = {
    title: "",

    description: "",
    tel1: "",
    tel2: "",
    tel3: "",

    imgId_qrWhatsapp: "",
    urlImg: "",

    mail1: "",
    mail2: "",
    mail3: "",
    address1: "",
    address2: "",
    hourOfOperation1: "",
    hourOfOperation2: "",
    hourOfOperation3: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    public contactUsService: ContactUsService,
    private toastr: ToastrService,
    public __ModalContactUsService: ModalContactUsService,
  ) { }

  ngDoCheck() {

    if (this.__ModalContactUsService.oculto == "" && this.__ModalContactUsService.idCFM > 0 && this.nVez == 0) {
      this.getContactUsByIdCFM().then(
        () => {

        }
      );
      this.nVez = 1;
    }
  }

  getContactUsByIdCFM(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        "idCFM": this.__ModalContactUsService.idCFM,
        "token": this.usersService.token
      }

      this.contactUsService.getByIdCFM(dataEnviar)
        .subscribe((respuesta: any) => {
          this.stateGeneral = 1
          if (respuesta.ok && respuesta.data_array.length > 0) {

            this.CONTACT_US_DATA.title = respuesta.data_array[0].title;
            this.CONTACT_US_DATA.description = respuesta.data_array[0].description;
            this.CONTACT_US_DATA.tel1 = respuesta.data_array[0].tel1;
            this.CONTACT_US_DATA.tel2 = respuesta.data_array[0].tel2;
            this.CONTACT_US_DATA.tel3 = respuesta.data_array[0].tel3;
            this.CONTACT_US_DATA.imgId_qrWhatsapp = respuesta.data_array[0].imgId_qrWhatsapp;
            this.CONTACT_US_DATA.urlImg = respuesta.data_array[0].routeImg;
            this.CONTACT_US_DATA.mail1 = respuesta.data_array[0].mail1;
            this.CONTACT_US_DATA.mail2 = respuesta.data_array[0].mail2;
            this.CONTACT_US_DATA.mail3 = respuesta.data_array[0].mail3;

            this.CONTACT_US_DATA.address1 = respuesta.data_array[0].address1;
            this.CONTACT_US_DATA.address2 = respuesta.data_array[0].address2;
            this.CONTACT_US_DATA.hourOfOperation1 = respuesta.data_array[0].hourOfOperation1;
            this.CONTACT_US_DATA.hourOfOperation2 = respuesta.data_array[0].hourOfOperation2;
            this.CONTACT_US_DATA.hourOfOperation3 = respuesta.data_array[0].hourOfOperation3;
            this.CONTACT_US_DATA.style = respuesta.data_array[0].style;
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  eventUpdate() {

    if (!this.validarCampo()) {
      return;
    }

    this.CONTACT_US_DATA.token = this.usersService.token
    this.CONTACT_US_DATA.idCFM = this.__ModalContactUsService.idCFM

    this.contactUsService.addOrUpdate(this.CONTACT_US_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("Datos actualizados.", 'Éxito!', { timeOut: 4000 });

          this.contactUsService.__refresh();
          this.cerrarModal();
        } else {
          this.toastr.error("Error", 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  cerrarModal() {
    this.nVez = 0
    this.stateGeneral = 0
    this.btnUpdate = 1

    this.CONTACT_US_DATA = {
      id: 0,
      idCFM: 0,
      title: "",

      description: "",
      tel1: "",
      tel2: "",
      tel3: "",

      imgId_qrWhatsapp: 0,
      urlImg: "",

      mail1: "",
      mail2: "",
      mail3: "",
      address1: "",
      address2: "",
      hourOfOperation1: "",
      hourOfOperation2: "",
      hourOfOperation3: "",

      style: 1,

      token: "",
    }
    this.__ModalContactUsService.ocultarModal()
  }

  limpiar() {
    this.ERROR_CONTACT_US_DATA = {
      title: "",

      description: "",
      tel1: "",
      tel2: "",
      tel3: "",

      imgId_qrWhatsapp: "",
      urlImg: "",

      mail1: "",
      mail2: "",
      mail3: "",
      address1: "",
      address2: "",
      hourOfOperation1: "",
      hourOfOperation2: "",
      hourOfOperation3: "",
    }
  }

  validarCampo() {
    this.limpiar();
    if (this.CONTACT_US_DATA.title.length > 100) {
      this.ERROR_CONTACT_US_DATA.title = "Este campo no puede tener mas de 100 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.description.length > 300) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.description = "Este campo no puede tener mas de 300 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.tel1.length > 15) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.tel1 = "Este campo no puede tener mas de 15 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.tel2.length > 15) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.tel2 = "Este campo no puede tener mas de 15 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.tel3.length > 15) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.tel3 = "Este campo no puede tener mas de 15 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.mail1.length > 50) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.mail1 = "Este campo no puede tener mas de 50 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.mail2.length > 50) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.mail2 = "Este campo no puede tener mas de 50 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.mail3.length > 50) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.mail3 = "Este campo no puede tener mas de 50 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.address1.length > 200) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.address1 = "Este campo no puede tener mas de 200 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.address2.length > 200) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.address2 = "Este campo no puede tener mas de 200 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.hourOfOperation1.length > 50) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.hourOfOperation1 = "Este campo no puede tener mas de 50 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.hourOfOperation2.length > 50) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.hourOfOperation2 = "Este campo no puede tener mas de 50 caracteres";
      return false;
    }
    if (this.CONTACT_US_DATA.hourOfOperation3.length > 50) {
      this.limpiar();
      this.ERROR_CONTACT_US_DATA.hourOfOperation3 = "Este campo no puede tener mas de 50 caracteres";
      return false;
    }
    this.limpiar();
    return true;
  }

}
