import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MenuService } from 'src/app/services/menu.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalMenuService } from './modal-menu.service';

@Component({
  selector: 'app-modal-menu',
  templateUrl: './modal-menu.component.html',
  styles: [
  ]
})
export class ModalMenuComponent implements DoCheck {

  nVez = 0
  btnPost = 1

  MENU_DATA = {
    id: 0,
    name: "",
    token: "",
  }

  MENU_DATA_ERROR = {
    name: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    private toastr: ToastrService,
    public modalMenuService: ModalMenuService,
    public menuService: MenuService,
  ) { }

  ngDoCheck() {
    if (this.modalMenuService.oculto === "" && this.modalMenuService.id > 0 && this.nVez === 0) {
      this.nVez = 1;

      this.MENU_DATA.id = this.modalMenuService.id;

      this.getMenuById()
    }
  }

  getMenuById(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "idMenu": this.MENU_DATA.id
      }

      this.menuService.getMenuById(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.MENU_DATA.name = respuesta.data_array[0].name
            resolve(true);
            return;
          }
          this.MENU_DATA.id = 0
          resolve(true);
        });
    })
  }

  add() {
    if (!this.validarCampo("name")) {
      return;
    }

    this.MENU_DATA.token = this.usersService.token;

    this.menuService.addMenu(this.MENU_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("", 'Menú Creado', { timeOut: 4500 });
          this.menuService.__refreshData();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }

      });
  }

  update() {
    if (!this.validarCampo("name")) {
      return;
    }

    this.MENU_DATA.token = this.usersService.token;

    this.menuService.updateMenu(this.MENU_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("", 'Menú actualizado', { timeOut: 4500 });
          this.menuService.__refreshData();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }

      });
  }

  validarCampo(campo: string) {
    if (campo === "name") {
      if (this.MENU_DATA.name === "") {
        this.MENU_DATA_ERROR.name = "Este campo no puede estar vacio";
        return false;
      } else if ((this.MENU_DATA.name).length > 50) {
        this.MENU_DATA_ERROR.name = "Este campo no puede tener mas de 50 caracteres";
        return false;
      } else {
        this.limpiar();
        return true;
      }
    }
    return true;
  }

  limpiar() {
    this.MENU_DATA_ERROR = {
      "name": "",
    }
  }

  cerrarModal() {
    this.nVez = 0
    this.btnPost = 1

    this.MENU_DATA = {
      id: 0,
      name: "",
      token: "",
    }

    this.MENU_DATA_ERROR = {
      name: "",
    }

    this.modalMenuService.ocultarModal()
  }

}
