import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CipHeaderService } from 'src/app/services/cip-header.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalHeaderService } from './modal-header.service';
import { URL_IMG } from 'src/app/config/config';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styles: [
  ]
})
export class ModalHeaderComponent implements DoCheck {

  nVez = 0

  CPI_HEADER = {
    id: 0,
    urlImage: "",
    backgroundColor: "",
    textColor: "",
    idImage: 0,
    menuId: 0,
    menuName: "",
    urlImg: "",
  }

  MENUS = []

  ERROR_CPI_HEADER = {
    backgroundColor: "",
    textColor: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    private toastr: ToastrService,
    public __ModalHeaderService: ModalHeaderService,
    public __CipHeaderService: CipHeaderService,
    public menuService: MenuService,
  ) { }

  ngDoCheck() {

    if (this.__ModalHeaderService.oculto == "" && this.__ModalHeaderService.idComponentInPage > 0 && this.nVez == 0) {
      this.CIP_getByIdCip().then(
        () => {
          this.getAllMenus().then(
            () => {

            }
          );
        }
      );
      this.nVez = 1;
    }
  }

  cerrarModal() {
    this.nVez = 0;
    this.CPI_HEADER = {
      id: 0,
      urlImage: "",
      backgroundColor: "",
      textColor: "",
      idImage: 0,
      menuId: 0,
      menuName: "",
      urlImg: "",
    }

    this.MENUS = []

    this.__CipHeaderService.__afterUpdateImage();
    this.__ModalHeaderService.ocultarModal()
  }

  getAllMenus(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this.menuService.getAllMenus()
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.MENUS = respuesta.data_array;
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  CIP_getByIdCip(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "idCip": this.__ModalHeaderService.idComponentInPage,
        "token": this.usersService.token
      }

      this.__CipHeaderService.getByIdCip(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            if (respuesta.data_array[0].i_route) {
              this.CPI_HEADER.urlImage = URL_IMG + respuesta.data_array[0].i_route;
            }
            this.CPI_HEADER.backgroundColor = respuesta.data_array[0].CIPH_backgroundColor;
            this.CPI_HEADER.textColor = respuesta.data_array[0].CIPH_colorText;
            this.CPI_HEADER.idImage = respuesta.data_array[0].i_id;
            this.CPI_HEADER.id = respuesta.data_array[0].CIPH_id;
            this.CPI_HEADER.urlImg = URL_IMG + respuesta.data_array[0].i_route;
            this.CPI_HEADER.menuId = respuesta.data_array[0].CIPH_idMenu;
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  setImg(e: any) {

    const dataEnviar = {
      "idImg": e.idImg,
      "idCIP": this.__ModalHeaderService.idComponentInPage,
      "token": this.usersService.token
    }

    this.__CipHeaderService.updateValueImage(dataEnviar)
      .subscribe((data: any) => {
        this.CIP_getByIdCip()
        if (data.ok) {
          this.toastr.success("Se agregó la imagen correctamente", 'Éxito!', { timeOut: 6000 });
        } else {
          this.toastr.error("OCURRIÓ UN ERROR, POR FAVOR VUELVE A ACTUALIZAR", 'Advertencia!', { timeOut: 6000 });
        }
      });
  }

  update() {
    if (!this.validarCampo()) {
      return;
    }

    const dataToSend = {
      token: this.usersService.token,
      backgroundColor: this.CPI_HEADER.backgroundColor,
      textColor: this.CPI_HEADER.textColor,
      idMenu: this.CPI_HEADER.menuId,
      idComponentInPage: this.__ModalHeaderService.idComponentInPage,
    }

    this.__CipHeaderService.uploadData(dataToSend)
      .subscribe((resp2: any) => {
        this.cerrarModal()
        if (resp2.ok) {
          this.toastr.success("", 'Actualizado con éxito!', { timeOut: 4000 });
        } else {
          this.toastr.error("Error", 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  limpiar() {
    this.ERROR_CPI_HEADER = {
      backgroundColor: "",
      textColor: "",
    }
  }

  validarCampo() {
    this.limpiar();

    if ((this.CPI_HEADER.backgroundColor).length > 25) {
      this.ERROR_CPI_HEADER.backgroundColor = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }

    if ((this.CPI_HEADER.textColor).length > 25) {
      this.ERROR_CPI_HEADER.textColor = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }

    this.limpiar();
    return true;
  }

  removeImg() {
    const dataEnviar = {
      "idComponentInPage": this.__ModalHeaderService.idComponentInPage,
      "token": this.usersService.token,
    }

    this.__CipHeaderService.removeImage(dataEnviar)
      .subscribe((data: any) => {
        this.CIP_getByIdCip();
      });
  }

}
