import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ImageWithTextService } from 'src/app/services/image-with-text.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalImageWithTextService } from './modal-image-with-text.service';
import { URL_IMG } from 'src/app/config/config';

@Component({
  selector: 'app-modal-image-with-text',
  templateUrl: './modal-image-with-text.component.html',
  styles: [
  ]
})
export class ModalImageWithTextComponent implements DoCheck {

  nVez = 0

  stateGeneral = 0

  collapseTitle = ""
  collapseDescription = ""
  collapseButtonAction = ""
  stateChooseImg = 0

  btnUpdate = 1

  boxMyRichText = {
    display: 0,
    requestData: 0
  }

  IWT_NOW = {
    id: 0,
    idImg: 0,
    urlImg: "",

    titleText: "",
    titleColor: "",
    titleFontSize: 0,
    titleBoldText: "unset",

    descriptionText: "",
    descriptionColor: "",
    descriptionFontSize: 0,
    descriptionBoldText: "unset",

    buttonText: "",
    buttonColor: "",
    buttonFontSize: 0,
    buttonBoldText: "unset",
    buttonBackgroundColor: "",
    buttonAlign: "",
    buttonRenderFor: "button",
    buttonBorder: 0,
    buttonUrl: "",

    backgroundColorTextBox: "",
    backgroundColorGeneralBox: "",
    imgAlign: "right",
  }

  ERROR_IWT_NOW = {
    backgroundColorTextBox: "",
    backgroundColorGeneralBox: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    public imageWithTextService: ImageWithTextService,
    private toastr: ToastrService,
    public __ModalImageWithTextService: ModalImageWithTextService,
  ) { }

  ngDoCheck() {

    if (this.__ModalImageWithTextService.oculto == "" && this.__ModalImageWithTextService.idComponentInPage > 0 && this.nVez == 0) {
      this.getImageWithText().then(
        () => {

        }
      );
      this.nVez = 1;
    }
  }

  setImg(e: any) {
    this.IWT_NOW.idImg = e.idImg;
    this.IWT_NOW.urlImg = URL_IMG + e.urlImg;
  }

  getImageWithText(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.boxMyRichText.display = 0
      this.stateGeneral = 0
      const dataEnviar = {
        "idComponentInPage": this.__ModalImageWithTextService.idComponentInPage,
        "token": this.usersService.token
      }

      this.imageWithTextService.getImageWithText(dataEnviar)
        .subscribe((respuesta: any) => {
          this.boxMyRichText.display = 1
          this.stateGeneral = 1

          if (respuesta.ok && respuesta.data_array.length > 0) {

            this.IWT_NOW.id = respuesta.data_array[0].id
            this.IWT_NOW.idImg = respuesta.data_array[0].idImg
            this.IWT_NOW.urlImg = URL_IMG + respuesta.data_array[0].routeImg

            this.IWT_NOW.titleText = (respuesta.data_array[0].titleText) ? respuesta.data_array[0].titleText : ""
            this.IWT_NOW.titleColor = (respuesta.data_array[0].titleColor) ? respuesta.data_array[0].titleColor : ""
            this.IWT_NOW.titleFontSize = (respuesta.data_array[0].titleFontSize) ? respuesta.data_array[0].titleFontSize : ""
            this.IWT_NOW.titleBoldText = respuesta.data_array[0].titleBoldText

            this.IWT_NOW.descriptionText = (respuesta.data_array[0].descriptionText) ? respuesta.data_array[0].descriptionText : ""
            this.IWT_NOW.descriptionColor = (respuesta.data_array[0].descriptionColor) ? respuesta.data_array[0].descriptionColor : ""
            this.IWT_NOW.descriptionFontSize = (respuesta.data_array[0].descriptionFontSize) ? respuesta.data_array[0].descriptionFontSize : ""
            this.IWT_NOW.descriptionBoldText = respuesta.data_array[0].descriptionBoldText

            this.IWT_NOW.buttonText = (respuesta.data_array[0].buttonText) ? respuesta.data_array[0].buttonText : ""
            this.IWT_NOW.buttonColor = (respuesta.data_array[0].buttonColor) ? respuesta.data_array[0].buttonColor : ""
            this.IWT_NOW.buttonFontSize = (respuesta.data_array[0].buttonFontSize) ? respuesta.data_array[0].buttonFontSize : ""
            this.IWT_NOW.buttonBoldText = respuesta.data_array[0].buttonBoldText
            this.IWT_NOW.buttonBackgroundColor = respuesta.data_array[0].buttonBackgroundColor
            this.IWT_NOW.buttonAlign = respuesta.data_array[0].buttonAlign
            this.IWT_NOW.buttonBorder = respuesta.data_array[0].buttonBorder
            this.IWT_NOW.buttonUrl = respuesta.data_array[0].buttonUrl

            this.IWT_NOW.backgroundColorTextBox = respuesta.data_array[0].backgroundColorTextBox
            this.IWT_NOW.backgroundColorGeneralBox = respuesta.data_array[0].backgroundColorGeneralBox
            this.IWT_NOW.imgAlign = respuesta.data_array[0].imgAlign

            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  eventUpdate() {
    this.btnUpdate = 0
    this.boxMyRichText.requestData = 1;
    this.stateGeneral = 0

    setTimeout(() => {
      this.update()
    }, 2000);
    return;
  }

  update() {

    if (!this.validarCampo()) {
      this.stateGeneral = 1
      return;
    }

    const dataEnviar = {
      "idCIP": this.__ModalImageWithTextService.idComponentInPage,
      "backgroundColorTextBox": this.IWT_NOW.backgroundColorTextBox,
      "backgroundColorGeneralBox": this.IWT_NOW.backgroundColorGeneralBox,

      "titleText": this.IWT_NOW.titleText,
      "titleFontSize": this.IWT_NOW.titleFontSize,
      "titleColor": this.IWT_NOW.titleColor,
      "titleBoldText": this.IWT_NOW.titleBoldText,

      "descriptionText": this.IWT_NOW.descriptionText,
      "descriptionColor": this.IWT_NOW.descriptionColor,
      "descriptionFontSize": this.IWT_NOW.descriptionFontSize,
      "descriptionBoldText": this.IWT_NOW.descriptionBoldText,

      "buttonText": this.IWT_NOW.buttonText,
      "buttonColor": this.IWT_NOW.buttonColor,
      "buttonFontSize": this.IWT_NOW.buttonFontSize,
      "buttonBoldText": this.IWT_NOW.buttonBoldText,
      "buttonBackgroundColor": this.IWT_NOW.buttonBackgroundColor,
      "buttonAlign": this.IWT_NOW.buttonAlign,
      "buttonRenderFor": this.IWT_NOW.buttonRenderFor,
      "buttonBorder": this.IWT_NOW.buttonBorder,
      "buttonUrl": this.IWT_NOW.buttonUrl,
      "imgAlign": this.IWT_NOW.imgAlign,
      "idImg": this.IWT_NOW.idImg,

      "token": this.usersService.token
    }

    this.imageWithTextService.addOrUpdate(dataEnviar)
      .subscribe((respuesta: any) => {
        this.btnUpdate = 1
        if (respuesta.ok) {
          this.imageWithTextService.__refreshData()
          this.toastr.success("Actualizado", 'Éxito!', { timeOut: 7000 });
          this.cerrarModal()
          return
        }
        this.toastr.error("ocurrió un error", 'Advertencia!', { timeOut: 7000 });
      });
  }

  setTextFromMyRichText(e: any, to: string) {
    setTimeout(() => {
      if (to == "title") {
        this.boxMyRichText.display = 0

        this.IWT_NOW.titleText = e.text
        this.IWT_NOW.titleFontSize = e.fontSizeText
        this.IWT_NOW.titleColor = e.colorText
        this.IWT_NOW.titleBoldText = e.boldText
        return
      }

      if (to == "description") {
        this.boxMyRichText.display = 0

        this.IWT_NOW.descriptionText = e.text
        this.IWT_NOW.descriptionFontSize = e.fontSizeText
        this.IWT_NOW.descriptionColor = e.colorText
        this.IWT_NOW.descriptionBoldText = e.boldText
        return
      }

      if (to == "button") {
        this.boxMyRichText.display = 0

        this.IWT_NOW.buttonText = e.text
        this.IWT_NOW.buttonColor = e.colorText
        this.IWT_NOW.buttonFontSize = e.fontSizeText
        this.IWT_NOW.buttonBoldText = e.boldText
        this.IWT_NOW.buttonBackgroundColor = e.backgroundColor
        this.IWT_NOW.buttonAlign = e.align
        this.IWT_NOW.buttonRenderFor = e.renderFor
        this.IWT_NOW.buttonBorder = e.border
        this.IWT_NOW.buttonUrl = e.url
        return
      }

    }, 500);
  }

  toggleCollapseTitle() {
    if (this.collapseTitle == "") {
      this.collapseTitle = "show"
    } else {
      this.collapseTitle = ""
    }
  }

  toggleCollapseDescription() {
    if (this.collapseDescription == "") {
      this.collapseDescription = "show"
    } else {
      this.collapseDescription = ""
    }
  }

  toggleCollapseButtonAction() {
    if (this.collapseButtonAction == "") {
      this.collapseButtonAction = "show"
    } else {
      this.collapseButtonAction = ""
    }
  }

  removeImgById() {
    this.IWT_NOW.idImg = 0;
    this.IWT_NOW.urlImg = '';
    // let dataEnviar = {
    //   "idImage": this.IWT_NOW.idImg,
    //   "idComponentInPage": this.__ModalImageWithTextService.idComponentInPage,
    //   "token": this.usersService.token
    // }

    // this.imageWithTextService.removeImgById(dataEnviar)
    //   .subscribe((respuesta: any) => {
    //     this.imageWithTextService.__refreshData()
    //     if (respuesta.ok) {
    //       this.toastr.success("Imagen eliminada", 'Éxito!', { timeOut: 7000 });
    //       this.getImageWithText()
    //       return
    //     }
    //     this.toastr.error("Error", 'Advertencia!', { timeOut: 7000 });
    //   });
  }

  limpiar() {
    this.ERROR_IWT_NOW = {
      backgroundColorTextBox: "",
      backgroundColorGeneralBox: "",
    }
  }

  validarCampo() {
    this.limpiar();

    if (this.IWT_NOW.backgroundColorTextBox && (this.IWT_NOW.backgroundColorTextBox).length > 25) {
      this.ERROR_IWT_NOW.backgroundColorTextBox = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }

    if (this.IWT_NOW.backgroundColorGeneralBox && (this.IWT_NOW.backgroundColorGeneralBox).length > 25) {
      this.ERROR_IWT_NOW.backgroundColorGeneralBox = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }

    this.limpiar();
    return true;
  }

  cerrarModal() {
    this.IWT_NOW = {
      id: 0,
      idImg: 0,
      urlImg: "",

      titleText: "",
      titleColor: "",
      titleFontSize: 0,
      titleBoldText: "unset",

      descriptionText: "",
      descriptionColor: "",
      descriptionFontSize: 0,
      descriptionBoldText: "unset",

      buttonText: "",
      buttonColor: "",
      buttonFontSize: 0,
      buttonBoldText: "unset",
      buttonBackgroundColor: "",
      buttonAlign: "",
      buttonRenderFor: "button",
      buttonBorder: 0,
      buttonUrl: "",

      backgroundColorTextBox: "",
      backgroundColorGeneralBox: "",
      imgAlign: "right",
    }

    this.nVez = 0
    this.stateGeneral = 0

    this.collapseTitle = ""
    this.collapseDescription = ""
    this.collapseButtonAction = ""

    this.btnUpdate = 1

    this.boxMyRichText = {
      display: 0,
      requestData: 0
    }

    this.ERROR_IWT_NOW = {
      backgroundColorTextBox: "",
      backgroundColorGeneralBox: "",
    }

    this.imageWithTextService.__refreshData()
    this.__ModalImageWithTextService.ocultarModal()
  }

}
