<div class="w-100" style="margin-bottom: 40px !important;">

    <div class="w-100 row d-block mt-3 mb-3">
        <button type="button" class="w-85 m-auto d-block position-relative">
            <span style="position: absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;">
                Subir imagen
            </span>
            <input class="w-100" style="opacity: 0;height: 90px;" id="modalIWTO_img1" type="file" name="file"
                (change)="onSelect($event)" accept="image/png, image/jpeg, image/jpg">
        </button>
    </div>

    <div class="input-group mb-2 mt-2 w-100">
        <input type="text" [(ngModel)]="imgSearch" (keyup)="searchImage()"
            class="form-control border-dark {{theme.background}} {{theme.text}}" placeholder="Buscar imagen">
        <div class="input-group-append">
            <span class="input-group-text border-dark {{theme.background}} {{theme.text}}"><i
                    class="fa fa-search"></i></span>
        </div>
    </div>

    <div *ngIf="imgSelected.idImg>0" class="input-group mb-2 mt-2 w-100 box-shadow-2 p-2">
        <i (click)="chooseImgAndEmit()" class="fa fa-check mr-1 mb-1 text-success position-absolute p-2 cursor-pointer"
            style="border: 2px solid #0CC27E;left: 5px;border-radius: 50%;"></i>
        <i class="fa fa-trash-o mr-1 mb-1 text-danger position-absolute p-2 cursor-pointer"
            style="border: 2px solid #FF586B;left: 50px;border-radius: 50%;"></i>

        <img class="w-50 d-block m-auto cursor-pointer" src="{{urlGeneral1}}{{imgSelected['urlImg']}}">
    </div>

    <div class="w-100 row">
        <div *ngFor="let image of IMG_DATA" class="col-lg-3 col-md-3 col-sm-6 p-2">
            <!-- <button type="button" (click)="removeImgById(image['I_id'])" class="btn btn-danger mb-0"
                style="position: relative;display: block;margin: auto;left: 45%;top: 25px;">
                <i class="fa fa-times"></i>
            </button> -->
            <img (click)="setImg(image['I_id'],image['I_route'],image['I_nameToDisplay'])"
                class="w-100 d-block m-auto cursor-pointer" id="img_{{image['I_id']}}"
                src="{{urlGeneral1}}{{image['I_route']}}">
        </div>
    </div>
</div>