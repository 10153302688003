<div class="fondo-negro animated fadeIn" *ngIf="modalVariantService.oculto===''">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">

        <div class="modal-dialog" role="document">
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 590px;">
                <div class="modal-header">
                    <h2 *ngIf="modalVariantService.id===0" class="modal-title">Agregar variante</h2>
                    <h2 *ngIf="modalVariantService.id>0" class="modal-title">Actualizar variante</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div *ngIf="modalVariantService.idProduct>0" class="modal-body">
                    <div class="card-body">

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Tipo de variante</label>
                            <div class="col-md-9">
                                <select (change)="onChangeTypeVarian()" class="form-control" name="idTypeVariant"
                                    [(ngModel)]="DATA.idTypeVariant">
                                    <option *ngFor="let op of OPTIONS_VARIANT" value="{{op['id']}}">{{op['value']}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row mb-1">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Nombre</label>
                            <div class="col-md-9">
                                <input *ngIf="DATA.idTypeVariant===3" type="text" readonly class="form-control"
                                    [(ngModel)]="DATA.name" name="name" />
                                <input *ngIf="DATA.idTypeVariant!==3" (change)="validarCampo('name')" type="text"
                                    class="form-control" [(ngModel)]="DATA.name" name="name" />
                                <p class="text-danger">{{DATA_ERROR.name}}</p>
                            </div>
                        </div>

                        <div *ngIf="DATA.idTypeVariant===2" class="form-group row mb-1">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Palabra clave 1</label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" [(colorPicker)]="DATA.key1"
                                    [style.background]="DATA.key1" [(ngModel)]="DATA.key1" name="key1" />
                                <p class="text-danger">{{DATA_ERROR.key1}}</p>
                            </div>
                        </div>

                        <div class="w-100 mt-2 mb-2" style="border-bottom: 2px solid #777;"></div>

                        <div class="form-group row mb-1">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Precio</label>
                            <div class="col-md-9">
                                <input (change)="validarCampo('price')" class="form-control" type="number"
                                    [(ngModel)]="DATA.price" name="price" />
                                <p class="text-danger">{{DATA_ERROR.price}}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Precio para
                                comparar</label>
                            <div class="col-md-9">
                                <input (change)="validarCampo('priceCompare')" class="form-control" type="number"
                                    [(ngModel)]="DATA.priceCompare" name="priceCompare" />
                                <p class="text-danger">{{DATA_ERROR.priceCompare}}</p>
                            </div>
                        </div>

                        <div *ngIf="modalVariantService.id>0" class="form-group row">
                            <button *ngIf="!DATA.i_route" type="button" class="w-85 m-auto"
                                style="position: relative;cursor: pointer;">
                                <span style="position: absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;">
                                    Subir imagen
                                </span>
                                <input class="w-100" style="opacity: 0;height: 90px;cursor: pointer;"
                                    id="fileUpload_IMAGEN" type="file" name="file" (change)="onSelect($event)"
                                    accept="image/png, image/jpeg, image/jpg">
                            </button>

                            <button *ngIf="DATA.i_route && DATA.i_route!==''" type="button"
                                (click)="removeVariantImgById(DATA.i_id,DATA.id)" class="btn btn-danger mb-0"
                                style="position: absolute;display: block;margin: auto;right: 40px;margin-right: 0;margin-top: -15px;">
                                <i class="fa fa-times"></i>
                            </button>
                            <img *ngIf="DATA.i_route && DATA.i_route!==''"
                                style="width: 85%;display: block;margin: auto;" src="{{urlGeneralImg}}{{DATA.i_route}}">
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button *ngIf="modalVariantService.id===0" (click)="addVariant()" type="button"
                        class="btn btn-primary btn-lg" style="padding-top: 2px !important;">Agregar</button>
                    <button *ngIf="modalVariantService.id>0" (click)="updateVariant()" type="button"
                        class="btn btn-primary btn-lg" style="padding-top: 2px !important;">Actualizar</button>
                </div>
            </div>
        </div>


    </div>
</div>