import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OurPricesService } from 'src/app/services/our-prices.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalOurPricesService } from './modal-our-prices.service';
import { URL_IMG } from 'src/app/config/config';

@Component({
  selector: 'app-modal-our-prices',
  templateUrl: './modal-our-prices.component.html',
  styles: [
  ]
})
export class ModalOurPricesComponent implements DoCheck {

  nVez = 0
  stateGeneral = 0
  btnUpdate = 0
  stateChooseImg = 0

  requestDataBoxTextHtml1 = 0
  requestDataBoxTextHtml2 = 0

  ourPriceData = {
    title: "",
    description: "",
    idCFM: 0,
    idImg: 0,
    backgroungColor: "",
    backgroundParticles: 0,
    urlImg: "",
    token: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    public ourPricesService: OurPricesService,
    private toastr: ToastrService,
    public modalOurPricesService: ModalOurPricesService,
  ) { }

  ngDoCheck() {

    if (this.modalOurPricesService.oculto === "" && this.modalOurPricesService.idComponentForMange > 0 && this.nVez === 0) {
      this.getOurPriceByIdCFM().then(
        () => {

        }
      );
      this.nVez = 1;
    }
  }

  setImg(e: any) {

    const dataEnviar = {
      "idImg": e.idImg,
      "idCIP": this.modalOurPricesService.idComponentForMange,
      "token": this.usersService.token
    }

    this.ourPricesService.updateValueImage(dataEnviar)
      .subscribe((data: any) => {
        this.getOurPriceByIdCFM()
        if (data.ok) {
          this.toastr.success("Se agregó la imagen correctamente", 'Éxito!', { timeOut: 6000 });
        } else {
          this.toastr.error("OCURRIÓ UN ERROR, POR FAVOR VUELVE A ACTUALIZAR", 'Advertencia!', { timeOut: 6000 });
        }
      });
  }

  getDataBoxTextHtml(e: any) {
    if (e === "<p></p>" || e === "" || !e) {
      this.toastr.error("Ingrese título", '', { timeOut: 7000 });
      setTimeout(() => {
        this.requestDataBoxTextHtml1 = 0
      }, 500);
      return
    }

    setTimeout(() => {
      this.requestDataBoxTextHtml1 = 0
      this.ourPriceData.title = e;
      this.eventUpdate(2)
    }, 500);
  }

  getDataBoxTextHtml2(e: any) {

    setTimeout(() => {
      this.requestDataBoxTextHtml2 = 0
      this.ourPriceData.description = e;
      this.eventUpdate(0)
    }, 500);
  }

  removeImgById(idImg: number) {
    const dataEnviar = {
      "idComponentInPage": this.modalOurPricesService.idComponentForMange,
      "token": this.usersService.token
    }

    this.ourPricesService.removeImage(dataEnviar)
      .subscribe((respuesta: any) => {
        this.ourPricesService.__refresh()
        if (respuesta.ok) {
          this.toastr.success("Imagen eliminada", 'Éxito!', { timeOut: 7000 });
          this.getOurPriceByIdCFM()
          return
        }
        this.toastr.error("Error", 'Advertencia!', { timeOut: 7000 });
      });
  }

  getOurPriceByIdCFM(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "idCFM": this.modalOurPricesService.idComponentForMange,
        "token": this.usersService.token
      }

      this.ourPricesService.getByIdCFM(dataEnviar)
        .subscribe((respuesta: any) => {
          let newUrlImg = "";
          this.stateGeneral = 1
          this.btnUpdate = 1
          if (respuesta.data_array.length > 0 && respuesta.ok) {
            if (respuesta.data_array[0].routeImg !== "" && respuesta.data_array[0].routeImg !== null) {
              newUrlImg = URL_IMG + respuesta.data_array[0].routeImg;
            }

            this.ourPriceData = {
              title: respuesta.data_array[0].title,
              description: respuesta.data_array[0].description,
              idCFM: respuesta.data_array[0].idCFM,
              idImg: respuesta.data_array[0].idImg,
              backgroungColor: respuesta.data_array[0].backgroungColor,
              backgroundParticles: respuesta.data_array[0].backgroundParticles,
              urlImg: newUrlImg,
              token: "",
            }
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  eventUpdate(refreshData = 0) {
    if (refreshData === 1) {
      this.requestDataBoxTextHtml1 = 1;
      return;
    }

    if (refreshData === 2) {
      this.requestDataBoxTextHtml2 = 1;
      return;
    }
    this.btnUpdate = 0
    this.ourPriceData.idCFM = this.modalOurPricesService.idComponentForMange;
    this.ourPriceData.token = this.usersService.token

    this.ourPricesService.addOrUpdate(this.ourPriceData)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.ourPricesService.__refresh()
          this.toastr.success("Actualizado", 'Éxito!', { timeOut: 7000 });
          this.cerrarModal()
          return
        }
        this.toastr.error("ocurrió un error", 'Advertencia!', { timeOut: 7000 });
      });

  }

  cerrarModal() {
    this.nVez = 0
    this.stateGeneral = 0
    this.btnUpdate = 0

    this.ourPriceData = {
      title: "",
      description: "",
      idCFM: 0,
      idImg: 0,
      backgroungColor: "",
      backgroundParticles: 0,
      urlImg: "",
      token: "",
    }

    this.ourPricesService.__refresh()
    this.modalOurPricesService.ocultarModal()
  }

}
