import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalUpdateFooterDataService {

  public oculto = "oculto";
  public idCIP = 0;
  public idFooter = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.idCIP = 0;
    this.idFooter = 0;
  }

  mostrarModal(idCIP: number, idFooter: number) {
    this.oculto = "";
    this.idCIP = idCIP;
    this.idFooter = idFooter;
  }
}
