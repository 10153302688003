import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyRichTextComponent } from './my-rich-text/my-rich-text.component';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { TableComponent } from './table/table.component';
import { RichTextComponent } from './rich-text/rich-text.component';
import { NgxEditorModule } from 'ngx-editor';
import { DragAndDropComponent } from './drag-and-drop/drag-and-drop.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CartMultifunctionComponent } from './cart-multifunction/cart-multifunction.component';
import { RouterModule } from '@angular/router';
import { ChatComponent } from './chat/chat.component';
import { SearchRoutesComponent } from './search-routes/search-routes.component';
import { ManageFileComponent } from './manage-file/manage-file.component';
import { ChatIncidentComponent } from './chat-incident/chat-incident.component';
import { ManageStylesComponent } from './manage-styles/manage-styles.component';


@NgModule({
  declarations: [
    MyRichTextComponent,
    TableComponent,
    DragAndDropComponent,
    RichTextComponent,
    CartMultifunctionComponent,
    ChatComponent,
    SearchRoutesComponent,
    ManageFileComponent,
    ChatIncidentComponent,
    ManageStylesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxEditorModule,
    ColorPickerModule,
    DragDropModule,
    RouterModule,
  ],
  exports: [
    MyRichTextComponent,
    DragAndDropComponent,
    TableComponent,
    RichTextComponent,
    CartMultifunctionComponent,
    ChatComponent,
    SearchRoutesComponent,
    ManageFileComponent,
    ChatIncidentComponent,
    ManageStylesComponent,
  ]
})
export class ComponentsPlusModule { }
