<div class="wrapper">

    <app-side-bar-admin></app-side-bar-admin>

    <!-- <app-header></app-header> -->


    <div class="main-panel" style="margin-top: 20px;">
        <router-outlet></router-outlet>
    </div>
</div>
<app-modal-component></app-modal-component>
<app-modal-image></app-modal-image>
<app-modal-update-footer-data></app-modal-update-footer-data>
<app-modal-item-slideshow></app-modal-item-slideshow>
<app-modal-image-with-text-overlay></app-modal-image-with-text-overlay>
<app-modal-image-with-text></app-modal-image-with-text>
<app-modal-menu></app-modal-menu>
<app-modal-menu-item></app-modal-menu-item>
<app-modal-choose-and-set-menu></app-modal-choose-and-set-menu>
<app-modal-product></app-modal-product>
<app-modal-entry></app-modal-entry>
<app-modal-variant></app-modal-variant>
<app-modal-header></app-modal-header>
<app-modal-our-prices></app-modal-our-prices>
<app-modal-item-our-prices></app-modal-item-our-prices>
<app-modal-description-item-our-prices></app-modal-description-item-our-prices>
<app-modal-contact-us></app-modal-contact-us>
<app-modal-section-text></app-modal-section-text>
<app-modal-page></app-modal-page>
<app-modal-section-counter></app-modal-section-counter>
<app-modal-item-counter></app-modal-item-counter>
<app-modal-gallery></app-modal-gallery>
<app-modal-gallery-item></app-modal-gallery-item>