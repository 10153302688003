import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CipFooterService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() afterUpdateDataFooter: EventEmitter<boolean> = new EventEmitter();
  __afterUpdateDataFooter() {
    this.afterUpdateDataFooter.emit();
  }

  @Output() afterUpdateImage: EventEmitter<boolean> = new EventEmitter();
  __afterUpdateImage() {
    this.afterUpdateImage.emit();
  }

  getFooterDataForStore(data: any) {
    const url = URL_SERVICIOS + "CIPFOOTER_getFooterDataForStore&ipClient=" + data.ipClient;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  getByIdCip(data: any) {
    const url = URL_SERVICIOS + "CIPFOOTER_getByIdCip&idCip=" + data.idCip + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  getItemsByFooterId(data: any) {
    const url = URL_SERVICIOS + "CIPFOOTER_getItemsByFooterId&idFooter=" + data.idFooter;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  updateFooterData(data: any) {
    const url = URL_SERVICIOS + "CIPFOOTER_updateFooterData";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {

        return of(err);
      });
  }

  addItemToFooter(data: any) {
    const url = URL_SERVICIOS + "CIPFOOTER_addItemToFooter";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {

        return of(err);
      });
  }

  updateItemToFooter(data: any) {
    const url = URL_SERVICIOS + "CIPFOOTER_updateItemToFooter";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {

        return of(err);
      });
  }

  removeItemFooter(data: any) {
    const url = URL_SERVICIOS + "CIPFOOTER_removeItemFooter";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {

        return of(err);
      });
  }
}
