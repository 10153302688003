import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalImageWithTextOverlayService {

  public oculto = "oculto";
  public idComponentInPage = 0;

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.idComponentInPage = 0;
  }
  mostrarModal(idComponentInPage: number) {
    this.oculto = "";
    this.idComponentInPage = idComponentInPage;
  }
}
