<div class="form-group row">
    <div class="col-md-12 p-0">
        <input [(ngModel)]="dataSearchRoute.wordSearched" (keyup)="searchRoute(dataSearchRoute.wordSearched,1)"
            autocomplete="none" type="text" (focus)="searchRoute(dataSearchRoute.wordSearched,1)"
            class="form-control {{theme.background}} {{theme.text}}" name="wordSearched" placeholder="Buscar ruta">
        <div class="box-shadow-2 overflow-auto"
            style="background: #fff;top: 40px;position: absolute;max-height: 280px;z-index: 9;">

            <ul *ngIf="stateDataToShow===1" class="pt-2 pb-2 m-0 text-left">
                <li *ngIf="ITEM_ROUTES['urlType']!==''"
                    class="p-2 font-medium-5 font-weight-bold bg-secondary text-white">Enlace</li>
                <li *ngIf="ITEM_ROUTES['urlType']!==''" class="p-1 border cursor-pointer selectedHover"
                    style="list-style-type:none;" (click)="setRoute(ITEM_ROUTES['urlType'])">
                    <div class="row">
                        <div class="col-12">
                            <span>
                                {{ITEM_ROUTES['urlType']}}
                            </span>
                        </div>
                    </div>
                </li>
                <li class="p-2 font-medium-5 font-weight-bold bg-secondary text-white">Páginas</li>
                <li class="p-1 border cursor-pointer selectedHover" style="list-style-type:none;"
                    (click)="setRoute('HOMEPAGE')">
                    <div class="row">
                        <div class="col-12">
                            <span>
                                <p class="font-weight-bold m-0 d-inline">Página principal</p>
                            </span>
                        </div>
                    </div>
                </li>
                <li *ngFor="let itemPage of ITEM_ROUTES.pages" class="p-1 border cursor-pointer selectedHover"
                    style="list-style-type:none;" (click)="setRoute('PAGE|'+itemPage['PAG_route'])">
                    <div class="row">
                        <div class="col-12">
                            <span>
                                <p class="font-weight-bold m-0 d-inline">Página: </p>
                                {{itemPage['PAG_name']}}
                            </span>
                        </div>
                    </div>
                </li>


                <li class="p-2 font-medium-5 font-weight-bold bg-secondary text-white">Productos</li>
                <li class="p-1 border cursor-pointer selectedHover" style="list-style-type:none;"
                    (click)="setRoute('ALL_PRODUCTS')">
                    <div class="row">
                        <div class="col-12">
                            <span>
                                <p class="font-weight-bold m-0 d-inline">Todos los productos</p>
                            </span>
                        </div>
                    </div>
                </li>
                <li *ngFor="let itemProduct of ITEM_ROUTES.products" class="p-1 border cursor-pointer selectedHover"
                    style="list-style-type:none;" (click)="setRoute('PRODUCT|'+itemProduct['P_id'])">
                    <div class="row">
                        <div class="col-9">
                            <div class="row">
                                <div class="col-7">
                                    <span>
                                        <p class="font-weight-bold m-0 d-inline">Producto: </p>
                                        {{itemProduct['P_name']}}
                                    </span>
                                </div>
                                <div class="col-5">
                                    <span>
                                        <p class="font-weight-bold m-0 d-inline">Precio: </p>
                                        {{itemProduct['variantes'][0]['price']}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <img class="w-100" src="{{urlGeneralImg}}{{itemProduct['variantes'][0]['i_route']}}">
                        </div>
                    </div>
                </li>


                <li class="p-2 font-medium-5 font-weight-bold bg-secondary text-white">Colecciones</li>
                <li *ngFor="let itemCollection of ITEM_ROUTES.collections"
                    class="p-1 border cursor-pointer selectedHover" style="list-style-type:none;"
                    (click)="setRoute('COLLECTION|'+itemCollection)">
                    <div class="row">
                        <div class="col-9">
                            <div class="row">
                                <div class="col-7">
                                    <span>
                                        <p class="font-weight-bold m-0 d-inline">Producto: </p>
                                        {{itemCollection['P_name']}}
                                    </span>
                                </div>
                                <div class="col-5">
                                    <span>
                                        <p class="font-weight-bold m-0 d-inline">Variante: </p>
                                        {{itemCollection['V_name']}}
                                    </span>
                                </div>
                                <div class="col-7">
                                    <span>
                                        <p class="font-weight-bold m-0 d-inline">Precio: </p>
                                        {{itemCollection['V_price']}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <img class="w-100" src="{{urlGeneralImg}}{{itemCollection['I_route']}}">
                        </div>
                    </div>
                </li>
            </ul>

        </div>
    </div>
</div>