import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  __refresh() {
    this.refresh.emit();
  }

  searchWarehouse(data: any) {
    const url = URL_SERVICIOS + "warehouse_search&wordToFind=" + data.wordToFind + "&offset=" + data.offset + "&limit=" + data.limit + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  addEntry(data: any) {
    const url = URL_SERVICIOS + "warehouse_addEntry";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

}
