import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { toHTML, Editor, Toolbar, toDoc } from 'ngx-editor';

@Component({
  selector: 'app-rich-text',
  templateUrl: './rich-text.component.html',
  styles: [
  ]
})
export class RichTextComponent implements DoCheck, OnDestroy, OnInit {

  @Output() emitOnChange = new EventEmitter<any>();
  @Input() requestData = 0;
  @Input() mydataTextHtml = "";
  @Input() mode = "all";
  @Input() displayWarningWhiteBG = 1;

  nVez = 0
  nVez2 = 0

  editor: Editor = new Editor();

  richDetailHtmlString: any
  html: Record<string, any> = toDoc("");

  // colorPresets = ['red', '#FF0000', 'rgb(255, 0, 0)'];

  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  colorPresets = ['#FF0000', '#FFFFFF', '#000000', 'FFEB00', '#2CFF00', '#00E6FF', '#004CFF', '#E900FF', '#860000',
    '#865100', '#2F8600', '#00867D', '#005686', '#002486', '#520086', '#FFE2B8'];

  constructor() { }

  changeData() {

    if (this.html) {
      this.richDetailHtmlString = toHTML(this.html);
    } else {
      this.richDetailHtmlString = ""
    }

    this.emitOnChange.emit(this.richDetailHtmlString);
  }

  ngDoCheck() {

    if (this.requestData == 1 && this.nVez == 0) {
      this.changeData()
      this.nVez = 1
    }

    if (this.mydataTextHtml != "" && this.nVez2 == 0) {
      this.html = toDoc(this.mydataTextHtml)
      this.nVez2 = 1
    }

    if (this.requestData == 0 && this.nVez == 1) {
      this.nVez = 0
    }

  }

  ngOnInit(): void {

    this.editor = new Editor();

    setTimeout(() => {
      const NgxEditor__MenuBar = document.getElementsByClassName("NgxEditor__MenuBar");
      const NgxEditor = document.getElementsByClassName("NgxEditor");
      const NgxEditor__MenuItem = document.getElementsByClassName("NgxEditor__MenuItem");
      const NgxEditor__Seperator = document.getElementsByClassName("NgxEditor__Seperator");
      const NgxEditor__Dropdown = document.getElementsByClassName("NgxEditor__Dropdown");

      Array.from(NgxEditor__MenuBar).forEach((el: any) => {
        el.style.display = "block"
        el.style.textAlign = "left"
      });

      Array.from(NgxEditor).forEach((el: any) => {
        el.style.textAlign = "left"
      });

      Array.from(NgxEditor__MenuItem).forEach((el: any) => {
        el.style.display = "inline-block"
      });

      Array.from(NgxEditor__Seperator).forEach((el: any) => {
        el.style.display = "none"
      });

      Array.from(NgxEditor__Dropdown).forEach((el: any) => {
        el.style.display = "inline-block"
        el.style.top = "-3px"
      });

    }, 500);

  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
