export const validateNumberRangeToChoose = (numberToValidate: number, min: number, max: number) => {
    let numberToReturn = (numberToValidate) ? numberToValidate : 0;
    if (numberToValidate > max) {
        numberToReturn = max;
    } else if (numberToValidate < min) {
        numberToReturn = min;
    }
    return numberToReturn
}

export const isJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const returnValueOfJsonByKey = (jsonObject: any, key: string) => {
    if (jsonObject && jsonObject.hasOwnProperty(key)) {
        return jsonObject[key];
    }
    return false;
}