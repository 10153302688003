import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  __refresh() {
    this.refresh.emit();
  }

  addOrUpdate(data: any) {
    const url = URL_SERVICIOS + "gallery_addOrUpdate";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  addOrUpdateItem(data: any) {
    const url = URL_SERVICIOS + "gallery_addOrUpdateItem";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  removeItemById(data: any) {
    const url = URL_SERVICIOS + "gallery_removeItemById";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  deleteComponentComplete(data: any) {
    const url = URL_SERVICIOS + "gallery_deleteComponentComplete";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  getBy(data: any, searchBy = 'idCFM') {
    const url = URL_SERVICIOS + "gallery_getBy&value=" + data.value + "&searchBy=" + searchBy;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  getItemsByIdGallery(data: any) {
    const url = URL_SERVICIOS + "gallery_getItemsByIdGallery&idGallery=" + data.idGallery;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }
}
