import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { of } from 'rxjs';
import { URL_SERVICIOS } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class IncidentFromClientService {

  constructor(
    public http: HttpClient
  ) { }

  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  __refreshData() {
    this.refreshData.emit();
  }

  search(data: any) {
    let url = URL_SERVICIOS + "incidentFromClient_search&wordToFind=" + data.wordToFind + "&searchBy=" + data.searchBy;
    url += "&searchExactly=" + data.searchExactly + "&offset=" + data.offset + "&limit=" + data.limit + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  getAllGroupByEmail(data: any) {
    const url = URL_SERVICIOS + "incidentFromClient_getAllGroupByEmail&busqueda=" + data.busqueda + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }

  add(data: any) {
    const url = URL_SERVICIOS + "incidentFromClient_add";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        return of(err);
      });
  }
}
