import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalChooseAndSetMenuService {

  public oculto = "oculto";
  public id_cip_header = 0;
  public forWhat = "";

  constructor() { }

  ocultarModal() {
    this.oculto = "oculto";
    this.id_cip_header = 0;
    this.forWhat = "";
  }
  mostrarModal(forWhat: string, id_cip_header: number) {
    this.oculto = "";
    this.id_cip_header = id_cip_header;
    this.forWhat = forWhat;
  }
}
