import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { APP_ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { StoreComponent } from './store/store.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceModule } from './services/service.module';
import { FormsModule } from '@angular/forms';
import { AdminComponent } from './admin/admin.component';
import { ModalModule } from './modals/modal.module';
import { ComponentsForManageModule } from './componentsForManage/components-for-manage.module';
import { ComponentsPlusModule } from './componentsPlus/components-plus.module';
// import { NgxEditorModule } from 'ngx-editor';

@NgModule({
  declarations: [
    AppComponent,
    StoreComponent,
    AdminComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    ComponentsModule,
    ComponentsForManageModule,
    ComponentsPlusModule,
    ModalModule,
    APP_ROUTES,
    // NgxEditorModule,
    ServiceModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
