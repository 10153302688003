import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { json_icons } from 'src/app/jsons/jsonIcons';
import { OurPricesService } from 'src/app/services/our-prices.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalItemOurPricesService } from './modal-item-our-prices.service';

@Component({
  selector: 'app-modal-item-our-prices',
  templateUrl: './modal-item-our-prices.component.html',
  styles: [
  ]
})
export class ModalItemOurPricesComponent implements DoCheck {

  confIcons = json_icons

  nVez = 0
  stateGeneral = 0
  btnUpdate = 0

  requestDataBoxTextHtml = 0
  text = ""
  collapseButtonAction = ""

  ARRAY_TO_SORT = []

  boxMyRichText = {
    display: 0,
    requestData: 0
  }

  ITEM_DATA = {
    id: "",
    title: "",
    price: 0,
    comparePrice: 0,
    prefijo: "",
    postfijo: "",
    position: 0,
    backgroundColor: "",
    colorText: "",
    buttonAction: {
      text: '',
      url: '',
      backgroundColor: '',
      colorText: '',
      fontSize: 0,
      boldText: '',
      border: 0,
      align: '',
    },
    displayPrice: 0,
    description: "",
    icon1: "",
    token: "",
  }

  ERROR_ITEM_DATA = {
    title: "",
    price: "",
    comparePrice: "",
    prefijo: "",
    postfijo: "",
    backgroundColor: "",
    colorText: "",
    textButtonAction: "",
    description: "",
    icon1: "",
    urlButtonAction: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    public ourPricesService: OurPricesService,
    private toastr: ToastrService,
    public modalItemOurPricesService: ModalItemOurPricesService,
  ) { }

  ngDoCheck() {

    if (this.modalItemOurPricesService.oculto === "" && this.modalItemOurPricesService.id > 0 && this.nVez === 0) {
      this.getItemById().then(
        () => {

        }
      );
      this.nVez = 1;
    }

    if (this.modalItemOurPricesService.oculto === "" && this.modalItemOurPricesService.idCFM > 0 && this.nVez === 0) {
      this.getItemsByIdCFM().then(
        () => {

        }
      );
      this.nVez = 1;
    }
  }

  getDataBoxTextHtml(e: any, to: string) {
    setTimeout(() => {
      if (to === "title") {
        this.requestDataBoxTextHtml = 0
        this.ITEM_DATA.description = e;
        return;
      }
      if (to === "button") {
        this.boxMyRichText.display = 1;
        this.boxMyRichText.requestData = 0;

        this.ITEM_DATA.buttonAction.text = e.text
        this.ITEM_DATA.buttonAction.colorText = e.colorText
        this.ITEM_DATA.buttonAction.fontSize = e.fontSizeText
        this.ITEM_DATA.buttonAction.boldText = e.boldText
        this.ITEM_DATA.buttonAction.backgroundColor = e.backgroundColor
        this.ITEM_DATA.buttonAction.align = e.align
        this.ITEM_DATA.buttonAction.border = e.border
        this.ITEM_DATA.buttonAction.url = e.url
      }
      setTimeout(() => {
        this.update()
      }, 300);
    }, 500);

  }

  update(refreshData = 0) {
    if (refreshData === 1) {
      this.requestDataBoxTextHtml = 1;
      this.boxMyRichText.requestData = 1;
      return;
    }

    if (!this.validarCampo()) {
      return;
    }

    const DATA_TO_SEND = {
      ...this.ITEM_DATA,
      buttonActionObject: JSON.stringify(this.ITEM_DATA.buttonAction),
      token: this.usersService.token
    }

    this.ourPricesService.updateItem(DATA_TO_SEND)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success("", 'Actualizado', { timeOut: 4500 });
          this.ourPricesService.__refresh();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }
      });
  }

  getItemsByIdCFM(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "idCFM": this.modalItemOurPricesService.idCFM,
        "token": this.usersService.token
      }

      this.ourPricesService.getItemsByIdCFM(dataEnviar)
        .subscribe((respuesta: any) => {
          this.boxMyRichText.display = 1
          this.stateGeneral = 1
          this.btnUpdate = 1
          if (respuesta.ok) {
            this.ARRAY_TO_SORT = respuesta.data_array
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  changeOrderPosition(e: any = []) {
    let newData = "";
    e.data.forEach((element: any) => {
      if (newData === "") {
        newData += element.id
      } else {
        newData += "|" + element.id
      }
    });
    const dataEnviar = {
      "page": "HOME_PAGE",
      "token": this.usersService.token,
      "data": newData,
      "previousIndex": e.previousIndex,
      "currentIndex": e.currentIndex,
    }

    this.ourPricesService.changeOrderPositionOfItem(dataEnviar)
      .subscribe((resp2: any) => {
        this.ourPricesService.__refresh()
        if (resp2.ok) {
          this.getItemsByIdCFM()
        } else {
          this.toastr.error(resp2.msn, 'Advertencia!', { timeOut: 4000 });
        }
      });
  }

  validarCampo() {
    this.limpiar();
    if (this.ITEM_DATA.title === "" || !this.ITEM_DATA.title) {
      this.ERROR_ITEM_DATA.title = "Este campo no puede estar vacio";
      return false;
    } else if ((this.ITEM_DATA.title).length > 50) {
      this.ERROR_ITEM_DATA.title = "Este campo no puede tener mas de 50 caracteres";
      return false;
    }

    if (this.ITEM_DATA.price < 0) {
      this.ERROR_ITEM_DATA.price = "Este campo tiene que ser numérico y positivo";
      return false;
    }

    if (this.ITEM_DATA.comparePrice < 0) {
      this.ERROR_ITEM_DATA.comparePrice = "Este campo tiene que ser numérico y positivo";
      return false;
    }

    if (this.ITEM_DATA.prefijo && (this.ITEM_DATA.prefijo).length > 10) {
      this.ERROR_ITEM_DATA.prefijo = "Este campo no puede tener mas de 10 caracteres";
      return false;
    }

    if (this.ITEM_DATA.postfijo && (this.ITEM_DATA.postfijo).length > 20) {
      this.ERROR_ITEM_DATA.postfijo = "Este campo no puede tener mas de 20 caracteres";
      return false;
    }

    if (this.ITEM_DATA.backgroundColor && (this.ITEM_DATA.backgroundColor).length > 25) {
      this.ERROR_ITEM_DATA.backgroundColor = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }

    if (this.ITEM_DATA.colorText && (this.ITEM_DATA.colorText).length > 25) {
      this.ERROR_ITEM_DATA.colorText = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }

    if (this.ITEM_DATA.buttonAction.text && (this.ITEM_DATA.buttonAction.text).length > 25) {
      this.ERROR_ITEM_DATA.textButtonAction = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }

    this.limpiar();
    return true;
  }

  limpiar() {
    this.ERROR_ITEM_DATA = {
      title: "",
      price: "",
      comparePrice: "",
      prefijo: "",
      postfijo: "",
      backgroundColor: "",
      colorText: "",
      textButtonAction: "",
      urlButtonAction: "",
      description: "",
      icon1: "",
    }
  }

  getItemById(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "id": this.modalItemOurPricesService.id,
        "token": this.usersService.token
      }

      this.ourPricesService.getItemById(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.boxMyRichText.display = 1
            this.stateGeneral = 1
            this.btnUpdate = 1
            console.log('..........................');
            console.log(respuesta.data_array[0]);
            console.log('..........................');

            this.ITEM_DATA = {
              ...this.ITEM_DATA,
              id: respuesta.data_array[0].id,
              title: respuesta.data_array[0].title,
              price: respuesta.data_array[0].price,
              comparePrice: respuesta.data_array[0].comparePrice,
              prefijo: respuesta.data_array[0].prefijo,
              postfijo: respuesta.data_array[0].postfijo,
              backgroundColor: respuesta.data_array[0].backgroundColor,
              colorText: respuesta.data_array[0].colorText,
              buttonAction: (respuesta.data_array[0].buttonActionObject) ? JSON.parse(respuesta.data_array[0].buttonActionObject) : {},
              displayPrice: respuesta.data_array[0].displayPrice,
              icon1: respuesta.data_array[0].icon1,
              description: respuesta.data_array[0].description,
            }

            this.text = respuesta.data_array[0].description

            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  toggleCollapseButtonAction() {
    if (this.collapseButtonAction === "") {
      this.collapseButtonAction = "show"
    } else {
      this.collapseButtonAction = ""
    }
  }

  cerrarModal() {
    this.nVez = 0
    this.stateGeneral = 0
    this.btnUpdate = 0
    this.text = ""
    this.requestDataBoxTextHtml = 0

    this.ARRAY_TO_SORT = []

    this.collapseButtonAction = ""

    this.boxMyRichText = {
      display: 0,
      requestData: 0
    }

    this.ITEM_DATA = {
      id: "",
      title: "",
      price: 0,
      comparePrice: 0,
      prefijo: "",
      postfijo: "",
      position: 0,
      backgroundColor: "",
      colorText: "",
      buttonAction: {
        text: '',
        url: '',
        backgroundColor: '',
        colorText: '',
        fontSize: 0,
        boldText: '',
        border: 0,
        align: '',
      },
      displayPrice: 0,
      description: "",
      icon1: "",
      token: "",
    }

    this.modalItemOurPricesService.ocultarModal()
  }

}
