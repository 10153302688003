import { Component, DoCheck, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ImageWithTextOverlayService } from 'src/app/services/image-with-text-overlay.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalImageWithTextOverlayService } from './modal-image-with-text-overlay.service';
import { URL_IMG } from 'src/app/config/config';

@Component({
  selector: 'app-modal-image-with-text-overlay',
  templateUrl: './modal-image-with-text-overlay.component.html',
  styles: [
  ]
})
export class ModalImageWithTextOverlayComponent implements DoCheck {

  nVez = 0

  stateGeneral = 0
  stateChooseImg = 0

  collapseTitle = ""
  collapseDescription = ""

  btnUpdate = 1

  boxMyRichText = {
    display: 0,
    requestData: 0
  }

  IWTO_NOW = {
    id: 0,
    idImg: 0,
    urlImg: "",

    titleText: "",
    titleColor: "",
    titleFontSize: 0,
    titleBoldText: "unset",

    descriptionText: "",
    descriptionColor: "",
    descriptionFontSize: 0,
    descriptionBoldText: "unset",

    backgroundColorTextBox: "",

    boxMain_backgroungColor: "",
    boxMain_backgroundParticles: 0,
  }

  ERROR_IWTO_NOW = {
    backgroundColorTextBox: "",
  }

  theme = {
    name: "light",
    background: "bg-light",
    background2: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public usersService: UsersService,
    public imageWithTextOverlayService: ImageWithTextOverlayService,
    private toastr: ToastrService,
    public __ModalImageWithTextOverlayService: ModalImageWithTextOverlayService,
  ) { }

  ngDoCheck() {

    if (this.__ModalImageWithTextOverlayService.oculto == "" && this.__ModalImageWithTextOverlayService.idComponentInPage > 0 && this.nVez == 0) {
      this.getImageWithTextOverlay().then(
        () => {

        }
      );
      this.nVez = 1;
    }
  }

  cerrarModal() {
    this.IWTO_NOW = {
      id: 0,
      idImg: 0,
      urlImg: "",

      titleText: "",
      titleColor: "",
      titleFontSize: 0,
      titleBoldText: "unset",

      descriptionText: "",
      descriptionColor: "",
      descriptionFontSize: 0,
      descriptionBoldText: "unset",

      backgroundColorTextBox: "",

      boxMain_backgroungColor: "",
      boxMain_backgroundParticles: 0,
    }

    this.nVez = 0

    this.collapseTitle = ""
    this.collapseDescription = ""

    this.btnUpdate = 1

    this.boxMyRichText = {
      display: 0,
      requestData: 0
    }

    this.ERROR_IWTO_NOW = {
      backgroundColorTextBox: "",
    }

    this.imageWithTextOverlayService.__refreshData()

    this.__ModalImageWithTextOverlayService.ocultarModal()
  }

  getImageWithTextOverlay(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.boxMyRichText.display = 0
      this.stateGeneral = 0
      const dataEnviar = {
        "idComponentInPage": this.__ModalImageWithTextOverlayService.idComponentInPage,
        "token": this.usersService.token
      }

      this.imageWithTextOverlayService.getImageWithTextOverlay(dataEnviar)
        .subscribe((respuesta: any) => {
          this.boxMyRichText.display = 1
          this.stateGeneral = 1
          if (respuesta.ok && respuesta.data_array.length > 0) {
            this.IWTO_NOW = {
              id: respuesta.data_array[0].id,
              idImg: respuesta.data_array[0].idImg,
              urlImg: URL_IMG + respuesta.data_array[0].routeImg,

              titleText: (respuesta.data_array[0].titleText) ? respuesta.data_array[0].titleText : "",
              titleColor: (respuesta.data_array[0].titleColor) ? respuesta.data_array[0].titleColor : "",
              titleFontSize: (respuesta.data_array[0].titleFontSize) ? respuesta.data_array[0].titleFontSize : "",
              titleBoldText: respuesta.data_array[0].titleBoldText,

              descriptionText: (respuesta.data_array[0].descriptionText) ? respuesta.data_array[0].descriptionText : "",
              descriptionColor: (respuesta.data_array[0].descriptionColor) ? respuesta.data_array[0].descriptionColor : "",
              descriptionFontSize: (respuesta.data_array[0].descriptionFontSize) ? respuesta.data_array[0].descriptionFontSize : "",
              descriptionBoldText: respuesta.data_array[0].descriptionBoldText,

              backgroundColorTextBox: respuesta.data_array[0].backgroundColorTextBox,

              boxMain_backgroungColor: (respuesta.data_array[0].boxMain_backgroungColor) ? respuesta.data_array[0].boxMain_backgroungColor : "",
              boxMain_backgroundParticles: (respuesta.data_array[0].boxMain_backgroundParticles == 1) ? 1 : 0,
            }
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  toggleCollapseTitle() {
    if (this.collapseTitle == "") {
      this.collapseTitle = "show"
    } else {
      this.collapseTitle = ""
    }
  }

  toggleCollapseDescription() {
    if (this.collapseDescription == "") {
      this.collapseDescription = "show"
    } else {
      this.collapseDescription = ""
    }
  }

  setTextFromMyRichText(e: any, to: string) {
    setTimeout(() => {
      if (to == "title") {
        this.boxMyRichText.display = 0

        this.IWTO_NOW.titleText = e.text
        this.IWTO_NOW.titleFontSize = e.fontSizeText
        this.IWTO_NOW.titleColor = e.colorText
        this.IWTO_NOW.titleBoldText = e.boldText
        return
      }

      if (to == "description") {
        this.boxMyRichText.display = 0

        this.IWTO_NOW.descriptionText = e.text
        this.IWTO_NOW.descriptionFontSize = e.fontSizeText
        this.IWTO_NOW.descriptionColor = e.colorText
        this.IWTO_NOW.descriptionBoldText = e.boldText
        return
      }

    }, 500);
  }

  eventUpdate() {
    this.btnUpdate = 0
    this.boxMyRichText.requestData = 1;
    this.stateGeneral = 0

    setTimeout(() => {
      this.update()
    }, 2000);
    return;
  }

  update() {

    if (!this.validarCampo()) {
      this.stateGeneral = 1
      return;
    }

    const dataEnviar = {
      "idCIP": this.__ModalImageWithTextOverlayService.idComponentInPage,
      "backgroundColorTextBox": this.IWTO_NOW.backgroundColorTextBox,

      "titleText": this.IWTO_NOW.titleText,
      "titleFontSize": this.IWTO_NOW.titleFontSize,
      "titleColor": this.IWTO_NOW.titleColor,
      "titleBoldText": this.IWTO_NOW.titleBoldText,

      "descriptionText": this.IWTO_NOW.descriptionText,
      "descriptionColor": this.IWTO_NOW.descriptionColor,
      "descriptionFontSize": this.IWTO_NOW.descriptionFontSize,
      "descriptionBoldText": this.IWTO_NOW.descriptionBoldText,

      "boxMain_backgroungColor": this.IWTO_NOW.boxMain_backgroungColor,
      "boxMain_backgroundParticles": this.IWTO_NOW.boxMain_backgroundParticles,
      "idImg": this.IWTO_NOW.idImg,

      "token": this.usersService.token
    }

    this.imageWithTextOverlayService.addOrUpdate(dataEnviar)
      .subscribe((respuesta: any) => {
        this.btnUpdate = 1
        if (respuesta.ok) {
          this.imageWithTextOverlayService.__refreshData()
          this.toastr.success("Actualizado", 'Éxito!', { timeOut: 7000 });
          this.cerrarModal()
          return
        }
        this.toastr.error("ocurrió un error", 'Advertencia!', { timeOut: 7000 });
      });
  }

  removeImgById() {
    this.IWTO_NOW.idImg = 0;
    this.IWTO_NOW.urlImg = '';
  }

  setImg(e: any) {
    this.IWTO_NOW.idImg = e.idImg;
    this.IWTO_NOW.urlImg = URL_IMG + e.urlImg;
  }

  limpiar() {
    this.ERROR_IWTO_NOW = {
      backgroundColorTextBox: "",
    }
  }

  validarCampo() {
    this.limpiar();

    if ((this.IWTO_NOW.backgroundColorTextBox).length > 25) {
      this.ERROR_IWTO_NOW.backgroundColorTextBox = "Este campo no puede tener mas de 25 caracteres";
      return false;
    }


    this.limpiar();
    return true;
  }

}
