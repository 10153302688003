<div class="app-sidebar {{theme.background}}">
    <div class="sidebar-header">
        <div class="logo clearfix {{theme.background}}">
            <a class="logo-text float-left">
                <span class="{{theme.text}} font-weight-bolder" style="font-size: 25px;">Admin</span>
            </a>
            <a (click)="toggleActiveSidebar()" class="nav-toggle d-none d-sm-none d-md-none d-lg-block text-dark">
                <i class="fa fa-bars" aria-hidden="true"></i>
            </a>
            <a id="sidebarClose" class="nav-close d-block d-md-block d-lg-none d-xl-none text-dark">
                <i class="ft-x"></i>
            </a>
        </div>
    </div>

    <div class="sidebar-content {{theme.background}}">
        <div class="nav-container">
            <ul id="main-menu-navigation" data-menu="menu-navigation" data-scroll-to-active="true"
                class="navigation navigation-main">
                <li routerLinkActive="active">
                    <a [routerLink]="['/admin/dashboard']" class="{{theme.text}}">
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                        <span class="font-medium-2 font-weight-bolder">Dashboard</span>
                    </a>
                </li>

                <li class="open sidebar-group-active">
                    <a class="ng-star-inserted">
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                        <span class="font-medium-2 font-weight-bolder">Editor- páginas</span>
                    </a>
                    <ul class="menu-content ng-star-inserted"
                        style="background: #f0f0f0;max-height: 300px;overflow: overlay;">
                        <li routerLinkActive="active" class="ng-star-inserted">
                            <a (click)="addPage()" class="ng-star-inserted text-white bg-primary text-center"
                                style="margin:20px">
                                <span class="font-medium-2">Agregar página</span>
                            </a>
                        </li>
                        <li routerLinkActive="active" class="ng-star-inserted">
                            <a [routerLink]="['/admin/homepage']" class="ng-star-inserted {{theme.text}}">
                                <i class="fa fa-angle-right m-0" aria-hidden="true"></i>
                                <span class="font-medium-1">Página principal</span>
                            </a>
                        </li>
                        <ng-container *ngFor="let pag of PAGES">
                            <li class="ng-star-inserted">
                                <a (click)="manageComponentFromPage(pag['PAG_id'])"
                                    class="ng-star-inserted {{theme.text}}">
                                    <i class="fa fa-angle-right m-0" aria-hidden="true"></i>
                                    <span class="font-medium-1">{{pag['PAG_name']}}</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
                <li routerLinkActive="active" class="ng-star-inserted">
                    <a [routerLink]="['/admin/manageIncidents']" class="ng-star-inserted {{theme.text}}">
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                        <span class="font-medium-2">Incidencias</span>
                    </a>
                </li>
                <li routerLinkActive="active" class="ng-star-inserted">
                    <a [routerLink]="['/admin/navigation']" class="ng-star-inserted {{theme.text}}">
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                        <span class="font-medium-2">Navegación</span>
                    </a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['/admin/manageusers']" class="{{theme.text}}">
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                        <span class="font-medium-2 font-weight-bolder">Usuarios</span>
                    </a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['/admin/managefiles']" class="{{theme.text}}">
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                        <span class="font-medium-2 font-weight-bolder">Mis archivos</span>
                    </a>
                </li>

                <li>
                    <a [routerLink]="['/']" class="{{theme.text}}">
                        <i class="fa fa-home" aria-hidden="true"></i>
                        <span class="font-medium-2 font-weight-bolder">Volver a la tienda</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="sidebar-background"></div>

</div>