<div class="fondo-negro animated fadeIn" [ngClass]="modalItemCounterService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">

            <!--  -->
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 660px;">
                <div class="modal-header">
                    <h2 class="modal-title">Agregar ítem contador</h2>

                    <button (click)="closeModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div [hidden]="stateGeneral===0" class="card-body">
                        <div class="row">
                            <label class="col-12 label-control font-medium-5 font-weight-bold mt-3 mb-3 text-left">
                                Datos del contenido
                            </label>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Número a contar</label>
                            <div class="col-md-9">
                                <input type="number" min="0" max="999999" class="form-control" (change)="validarCampo()"
                                    [(ngModel)]="ITEM_COUNTER_NOW.quantityToCount" name="title" />
                                <p class="text-danger">{{ERROR_ITEM_COUNTER_NOW.quantityToCount}}</p>
                            </div>
                        </div>
                        <div class="form-group row mt-2">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Ícono presentación</label>
                            <div class="col-md-9">
                                <select [(ngModel)]="ITEM_COUNTER_NOW.icon" name="users-list-role" class="form-control">
                                    <option value="plus">+</option>
                                    <option value="minus">-</option>
                                    <option value="percent">%</option>
                                </select>
                            </div>
                        </div>

                        <div class="accordion">
                            <div class="card box-shadow-2">
                                <div class="card-header p-0">
                                    <h2 class="mb-0">
                                        <button
                                            class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                            type="button" (click)="toggleCollapseDescription()">
                                            <h4 class="text-bold-600 m-3">Descripción -
                                                {{ITEM_COUNTER_NOW.descriptionText}}</h4>
                                        </button>
                                    </h2>
                                </div>

                                <div class="collapse {{theme.background2}} {{theme.text}} {{collapseDescription}}">
                                    <div class="card-body">
                                        <app-my-rich-text *ngIf="boxMyRichText.display===1"
                                            [colorText]="ITEM_COUNTER_NOW.descriptionColor"
                                            [fontSizeText]="ITEM_COUNTER_NOW.descriptionFontSize"
                                            [boldText]="ITEM_COUNTER_NOW.descriptionBoldText"
                                            [text]="ITEM_COUNTER_NOW.descriptionText"
                                            [requestData]="boxMyRichText.requestData"
                                            (emitOnChange)="setTextFromMyRichText($event,'description')">
                                        </app-my-rich-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="stateGeneral===0" class="content-wrapper">
                        <i style="position: absolute;top: 170px;left: 50%;margin: -35px 0 0 -35px;"
                            class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>


                </div>
                <div *ngIf="btnUpdate===1" class="modal-footer">
                    <button (click)="closeModal()" type="button" class="btn btn-danger">cerrar</button>

                    <button (click)="eventUpdate()" type="button" class="btn btn-primary">Guardar</button>
                </div>
            </div>

        </div>
    </div>
</div>