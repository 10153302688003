<div class="fondo-negro animated fadeIn" *ngIf="modalProductService.oculto===''">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">

        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 590px;">
                <div class="modal-header">
                    <h2 *ngIf="modalProductService.id===0" class="modal-title">Agregar producto</h2>
                    <h2 *ngIf="modalProductService.id>0" class="modal-title">Actualizar producto</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">
                    <div class="card-body">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label text-left font-weight-bold">Nombre</label>
                            <div class="col-md-9">
                                <input class="form-control" [(ngModel)]="DATA_TO_ADD.name" name="name" />
                                <p class="text-danger">{{DATA_TO_ADD_ERROR.name}}</p>
                            </div>
                        </div>

                        <div *ngIf="modalProductService.id>0" class="form-group row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-md-5 col-form-label text-left font-weight-bold">Estado</label>
                                    <div class="col-md-7">
                                        <select class="form-control" name="stateNormal"
                                            [(ngModel)]="DATA_TO_ADD.stateNormal">
                                            <option value="1">PÚBLICO</option>
                                            <option value="0">OCULTO</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="col-md-5 col-form-label text-left font-weight-bold">
                                        Vender aún sin stock
                                    </label>
                                    <div class="col-md-7">
                                        <select class="form-control" name="sellAfterOutOfStock"
                                            [(ngModel)]="DATA_TO_ADD.sellAfterOutOfStock">
                                            <option value="1">SI</option>
                                            <option value="0">NO</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="col-12 col-form-label text-left font-weight-bold">Descripción</label>
                            <app-rich-text [requestData]="requestDataBoxTextHtml" [mydataTextHtml]="text"
                                (emitOnChange)="getDataBoxTextHtml($event)"></app-rich-text>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button *ngIf="modalProductService.id===0" (click)="addProduct(1)" type="button"
                        class="btn btn-primary btn-lg" style="padding-top: 2px !important;">Agregar</button>

                    <button *ngIf="modalProductService.id>0" (click)="updateProduct(1)" type="button"
                        class="btn btn-primary btn-lg" style="padding-top: 2px !important;">Actualizar</button>
                </div>
            </div>
        </div>


    </div>
</div>