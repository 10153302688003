import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { URL_IMG } from 'src/app/config/config';
import { ImagesService } from 'src/app/services/images.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-manage-file',
  templateUrl: './manage-file.component.html',
  styles: [
  ]
})
export class ManageFileComponent implements OnInit {

  @Output() emitImageChoosed = new EventEmitter<any>();

  urlGeneral1 = URL_IMG;

  urlImageToShow = '';

  imgSearch = '';

  IMG_DATA = []

  imgSelected = {
    idImg: 0,
    urlImg: '',
    nameImg: '',
  }

  theme = {
    name: "light",
    background: "bg-light",
    text: "text-dark",
    btnType1: "btn-primary",
  }

  constructor(
    public _ImagesService: ImagesService,
    public usersService: UsersService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.searchImage()
  }

  searchImage(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dataEnviar = {
        "token": this.usersService.token
      }

      this._ImagesService.getByAll(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.IMG_DATA = respuesta.dataImages
            resolve(true);
            return;
          }
          resolve(true);
        });
    })
  }

  chooseImgAndEmit() {
    this.emitImageChoosed.emit(this.imgSelected)
  }

  addFile() {

  }

  setImg(idImg: number, urlImg: string, nameImg: string) {
    if (this.imgSelected.idImg > 0) {
      document.getElementById('img_' + this.imgSelected.idImg)!.style.border = '0px';
    }
    document.getElementById('img_' + idImg)!.style.border = '1px solid #2EAA3B';

    const jsonToSet = {
      idImg,
      urlImg,
      nameImg,
    }
    this.imgSelected = jsonToSet;
  }

  removeImgById(idImg: string) {
    const dataEnviar = {
      "idImage": idImg,
      "token": this.usersService.token
    }

    // this._ImagesService.removeImgById(dataEnviar)
    //   .subscribe((respuesta: any) => {
    //     this.searchImage();
    //     if (respuesta.ok) {
    //       this.toastr.success("Imagen eliminada", 'Éxito!', { timeOut: 7000 });
    //       return
    //     }
    //     if (respuesta.quantityUsed > 0) {
    //       this.toastr.error("Esta images está siendo usada por algún otro componente. (" + respuesta.dataValidation.join() + ")", 'Advertencia!', { timeOut: 7000 });
    //       return
    //     }
    //     this.toastr.error("Error", 'Advertencia!', { timeOut: 7000 });
    //   });
  }

  onSelect(event: any) {

    const elemento = event.target;

    if (elemento.value.split('.')[1] != "png" && elemento.value.split('.')[1] != "jpg" && elemento.value.split('.')[1] != "jpeg" && elemento.value.split('.')[1] != "JPG") {
      // swal.fire("Remember that you can only upload images of type png, jpg and jpeg", "", "error");
      this.toastr.error("Solo se permiten los formatos: png, jpg and jpeg", 'Advertencia!', { timeOut: 6000 });

      (document.getElementById("modalIWTO_img1") as HTMLInputElement).value = "";
      return;
    }

    if (elemento.files.length > 0) {

      if ((elemento.files[0].size / 1000) > 2048) {
        this.toastr.error("Solo se permite imagenes con tamaño menor a 2048 Kilobytes", 'Advertencia!', { timeOut: 6000 });

        (document.getElementById("modalIWTO_img1") as HTMLInputElement).value = "";
        return;
      }

      const formData = new FormData();
      formData.append('token', this.usersService.token);

      formData.append('file', elemento.files[0]);

      this._ImagesService.uploadImage(formData)
        .subscribe((data: any) => {
          if (data.ok) {
            this.toastr.success("Se agregó la imagen correctamente", 'Éxito!', { timeOut: 6000 });
          } else {
            this.toastr.error("OCURRIÓ UN ERROR, POR FAVOR VUELVE A ACTUALIZAR", 'Advertencia!', { timeOut: 6000 });
          }
          (document.getElementById("modalIWTO_img1") as HTMLInputElement).value = "";
          this.searchImage();
        });

    }
  }
}
